.simple-table{
  font-size:15px;
  vertical-align: top;
  width: 100%;
  border-collapse: collapse;
  @media (min-width: $breakpoint--tablet + 1) and (max-width: $breakpoint--normal){
    font-size: 14px;
    p{
      font-size: 14px !important;
    }
  }
  th{
      text-align: left;
      font-weight: 600;
      color: rgba($purple-cl,0.5);
      padding-bottom: 20px;
      +th{
          padding-left: 20px;
      }
  }
    td{
        vertical-align: top;
        padding-bottom: 25px;
        span{
            display: block;
        }
        p{
            line-height: 25px;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        +td{
            padding-left: 20px;
        }
    }
    .disabled{
        color: rgba($purple-cl,0.5);
    }
   &.td-big-spaces{
       td{
           padding-bottom: 35px;
       }
   }
    @media(max-width: $breakpoint--phablet){
        display: block;
        tr{display: block;
          +tr{
              margin-bottom: 30px
          }
        }
        td{display: block;padding: 0 0 5px !important;}
        th{display: none;}
    }
    .th-mobile{
        display: none;
        @media (max-width: $breakpoint--phablet){
            display: block;
            color: rgba($purple-cl,0.5);
        }
    }
}
.w170{
  width: 170px;
}