.dropdown {
    position: relative;
    cursor: pointer;

    &.lang-select {
        font-size: $font-18;
        text-transform: uppercase;

        .ic_arr_d {
            font-size: 10px;
            margin-left: 10px;
            display: inline-block;
            vertical-align: middle;
        }
    }

    .dropdown__menu {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background: $white-cl;
        color: $grey-cl;
        padding: 20px 0;
        margin: 0;
        list-style: none;
        min-width: 100%;
        text-align: left;
        z-index: 1000;
        box-shadow: 0 10px 45px rgba(94,103,111,.15);
    }
}
.dropdropdown__trigger{
  color:$white-cl;
}
.dm-item-inner{
  padding: 13px 40px;
  display: block;
  white-space: nowrap;
  color: $grey-cl;
  font-size: $font-20;
  @media only screen and (max-width:1439px){
    padding: 12px 30px;
  }
  &:hover{
    background: rgba( $grey-cl, .05 );
  }
  .ic_arr_r{
    font-size: 10px;
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;
  }
}