.letter-tabs {
    margin: 0 0 20px;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    z-index: 2;
    li {
        position: relative;
        cursor: pointer;
        &:after{
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 5px;
            background: transparent;
            top:100%;
            left: 0;

            transition: 0.3s ease;
        }
        &.current:after{
            background: $primary-cl;
            margin-top: 10px;
        }
    }
}

.letter-tab-content{
    display: none;
    text-align: center;
    &.current{
        display: inherit;
    }
}
.list-links-tabs{
    .acc__toggle{padding-bottom: 10px;
       transition: 0.2s ease;
        &:hover{
            color: rgba($purple-cl,0.5);
        }
        &.showed{
            color: $primary-cl;
        }
    }
    ul{

        li{
            &:first-child{
                padding-top: 15px;
            }
            &:last-child{
                padding-bottom: 15px;
            }

            a{
                font-size: 15px;
                line-height: 30px;
                color: $dark-blue-cl;
                &:hover{
                    color: $primary-cl;
                }
            }
            i{
                font-size: 8px;
                vertical-align: middle;
                margin-right: 5px;
            }
            +li{
                margin-top: 15px;
            }
        }
    }
}