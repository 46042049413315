.course-card{
  &__img{
     display: block;
     margin-bottom: 20px;
    max-height: 150px;
    overflow: hidden;
    //@media (max-width: $breakpoint--phablet) {
    //  max-height: none;
    //}
    img{
      vertical-align: top;
      min-height: 150px;
      object-fit: cover;
    }
  }
   &__status{
     margin-bottom: 6px;
   }
   &__title{
     font-size: 22px;
     font-family: $font-family-serif;
     a{
       color: $purple-cl;
       line-height: 30px;
       &:hover{
         color: $primary-cl;
       }
     }
   }
}