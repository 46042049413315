.news{
  &__container{
    position: relative;
    z-index:2;
    width: 100%;
    @media (min-width: $breakpoint--phablet + 1){
      display: flex;
      height: 100%;
    }
  }
  &__preview-item{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
    height: 33.3333%;
    overflow: hidden;
    //background: $primary-cl;
    background: #1b224e;
    img{
      display: block;
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
      position: absolute;
      transition: 0.3s ease;
      left: 0;
      bottom: 0;
      z-index: 1;
      opacity: .45;
    }
    @media(max-width: $breakpoint--tablet) {
      min-height: 200px;
    }
    &:hover{
      background: $primary-cl;
      img{
        opacity: 0.4;
      }
    }
  }
  &__preview-title{
    color: $white-cl;
    position: relative;
    padding: 30px 40px;
    text-align: left;
    z-index: 2;
    transition: 0.4s ease;
    @media(max-width: $breakpoint--phone) {
      padding: 25px;
    }
  }
  &__menu{
    padding: 90px 50px 90px 10vw;
    @media(max-width: $breakpoint--tablet) {
      padding: 100px 40px;
    }
    @media(max-width: $breakpoint--phablet) {
      padding: 100px 40px 50px;
    }
    @media (min-width: $breakpoint--phablet + 1){
      display: table;
      width: 35%;
      height: 100vh;
    }
    @media(max-width: $breakpoint--phone) {
      padding: 100px 25px 35px;
    }
    &-td{
      @media (min-width: $breakpoint--phablet + 1){
        display: table-cell;
        vertical-align: middle;
      }
    }
    ul{
      padding: 0;
      margin: 0;
      list-style: none;
      @media(max-width: $breakpoint--phablet) {
        display: flex;
        justify-content: space-between;
      }
      @media(max-width: $breakpoint--phone) {
        flex-wrap: wrap;
      }
    }
    li{
      margin-top: 40px;
      @media (min-width: $breakpoint--phablet + 1){
        margin-top: 7vh;
      }
      @media(min-width: $breakpoint--tablet + 1) {
        margin-top: 7vh;
      }
      @media(max-width: $breakpoint--phone) {
        width: 50%;
        margin-top: 25px;
      }
    }
    .link-w{
      cursor: pointer;
        //font-size: 30px;
      font-size: 2vw;
      @media(max-width: $breakpoint--tablet) {
        font-size: 3vw;
      }
      @media(max-width: $breakpoint--phone) {
        font-size: 20px;
      }
    }
    .current .link-w{
      color: $primary-cl;
    }
  }
  &__previews{
     overflow: hidden;
    background: $primary-cl;
    @media (min-width: $breakpoint--phablet + 1){

        width: 65%;
    }
  }
  &__date{
    display: block;
    font-size: 15px;
    font-family: $font-family;
    text-transform: uppercase;
    &.date--calendar {
      color: $white-cl;
      padding-left: 25px;
      background: url("../img/calendar_white.svg") 0 0 no-repeat;
      background-size: 16px 18px;
      min-height: 18px;
      margin-bottom: 6px;
      line-height: 22px;
      margin-right: 0;
    }
  }
}
.new__previews{
  width: 75%;
  @media (min-width: $breakpoint--phablet + 1){
    height: 100%;
  }
  @media (max-width: 767px){
    width: 100%;
  }
   .letter-tab-content{
     height: 100%;
   }
}
.read-more-txt{
  display: block;
  color: $white-cl;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 20px;
  font-family: $font-family;
  position: absolute;
  left: 0;
  bottom:0;
  z-index: 3;
  padding: 30px 40px;
  opacity: 0;
  transform: translateY(30px);
  transition: 0.4s ease;
  .icon-arrow-right{
    height: 8px;
    width: 21px;
    display: inline-block;
    vertical-align: middle;
    background: url(../img/arrow-right.svg) center center no-repeat;
    margin-left: 5px;
  }
  @media(max-width: $breakpoint--tablet){
    font-size: 18px;
  }
  @media(max-width: $breakpoint--phablet){
    font-size: 16px;
  }
  @media(max-width: $breakpoint--phone){
    padding: 25px;
  }
}

.news__preview-item:hover {
  .news__preview-title{
    padding-bottom: 80px;
  }
  .read-more-txt{
    opacity: 1;
    transform: translateY(0);
  }
}
.speakers-grid{
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
}
.speaker{
  &__preview-item{
    width: 50%;
    min-height: 50vh;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: $primary-cl;
    img{
      position: absolute;
      min-height: 100%;
      min-width: 100%;
      left:50%;
      top:50%;
      transition: opacity 0.3s ease-in-out;
      transform: translate(-50%,-50%);
    }
    &:hover{
      img{
        opacity: 0.4;
      }
    }
    @media(max-width: $breakpoint--phablet){
      min-height: 270px;
    }
    @media(max-width: $breakpoint--phone){
      width: 100%;
      min-height: 200px;
      &:last-child{
        display: none;
      }
    }
  }
  &__caption{
    padding: 20px 40px 40px;
    position: relative;
    z-index: 2;
    color: $white-cl;
    text-align: left;
    @media(max-width: $breakpoint--phone){
      padding: 20px;
    }
    span{
      display: block;
    }
  }
  &__subject{
    display: block;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  &__preview-title{
    margin-bottom: 15px;
    &:after{
      content: '';
      display: block;
      width: 70px;
      height: 5px;
      background: $primary-cl;
      margin-top: 20px;
    }
  }
  &__name{
    text-transform: uppercase;
  }
}