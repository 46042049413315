input[type="text"],
input[type="password"],
input[type="tel"],
input[type="email"],
input[type="search"]{
    border: 2px solid $white-cl;
    outline: none;
    border-radius: 35px;
    box-shadow: none;
    -webkit-appearance: none;
    font-size: $font-18;
    padding: 2px 30px;
    height: 50px;
    transition: $anim-02;
    font-family: $font-family-serif;
    background: $white-cl;
}
input[type="text"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus{
  border-color:#7982b5;
}

input[type="search"]{
    font-family: "Raleway",sans-serif;
}
textarea{
    border: 2px solid $white-cl;
    outline: none;
    border-radius: 20px;
    box-shadow: none;
    -webkit-appearance: none;
    font-size: $font-18;
    padding: 15px 30px;
    height: 160px;
    transition: $anim-02;
    font-family: $font-family-serif;
    background: $white-cl;
    width: 100%;
    resize: none;
    &:focus{
        border-color:#7982b5;
    }
}

input[type="text"].lined,
input[type="password"].lined,
input[type="tel"].lined,
input[type="email"].lined {
    border: none;
    border-bottom: 2px solid $border-grey;
    outline: none;
    border-radius: 0;
    font-size: $font-20;
    padding: 5px 30px;
    height: 70px;
    transition: $anim-02;
    -webkit-transition: $anim-02;
    background: transparent;
    position: relative;
    z-index: 1;

    @media only screen and (max-width:$breakpoint--phone) {
        padding: 5px 15px;
        height: 50px;
    }
}

input[type="text"].lined:focus,
input[type="password"].lined:focus,
input[type="tel"].lined:focus,
input[type="email"].lined:focus {
    border-color: $primary-cl;
}
textarea.lined {
    border: none;
    border-bottom: 2px solid $border-grey;
    outline: none;
    border-radius: 0;
    font-size: $font-20;
    padding: 23px 30px 5px;
    height: 70px;
    transition: $anim-02;
    -webkit-transition: $anim-02;
    resize: none;
    font-family: $font-family;
    background: transparent;
    overflow: auto;
    position: relative;
    z-index: 1;

    @media only screen and (max-width:$breakpoint--phone) {
        padding: 10px 15px 5px;
        height: 50px;
    }

    &:focus {
        border-color: $primary-cl;
    }
}
.line {
    margin-bottom: 20px;
    &:last-child{
        margin-bottom: 0;
    }

    input[type="text"],
    input[type="password"],
    input[type="tel"],
    input[type="email"]{
        width: 100%;
    }
}
input.error-input,
textarea.error-input{
    border-color: $primary-cl;
}
.error-txt{
    font-size: 13px;
    margin: 10px 0 0 0;
    color: $primary-cl;
    text-transform: none;
    font-family: $font-family;
    line-height: 16px;
    text-align: center;
}
.simple-form{
    .error-txt{
        text-align: left;
        font-size: 13px;
    }
}
.select2-selection__rendered,
.sort-type{
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 15px;
    color: $dark-blue-cl;
    font-weight: 600;
    line-height: 20px;
    -webkit-appearance: none;
    text-transform: uppercase;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #111C61;
    opacity: .5
}
::-moz-placeholder { /* Firefox 19+ */
    color: #111C61;
    opacity: .5}
:-ms-input-placeholder { /* IE 10+ */
    color: #111C61;
    opacity: .5}
:-moz-placeholder { /* Firefox 18- */
    color: #111C61;
    opacity: .5}

.form-actions{
    display: flex;
    flex-direction: column;
    .btn{margin-top: 20px}
    @media (min-width: $breakpoint--phone + 1) {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        .btn{
            margin-right: 30px;
            margin-top: 0;
        }
    }

}
.simple-form input,
.simple-form textarea{
    font-size: 15px;
    font-family: $font-family;
    padding-left: 25px;
    padding-right: 25px;
}
.simple-checkbox{
    position: relative;
    padding-left: 30px;
    line-height: 20px;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    input[type="checkbox"]{
        display: none;
    }
    i{
        display:block;
        width: 20px;
        height: 20px;
        background: $white-cl;
        border-radius: 3px;
        position: absolute;
        left: 0;
        top: 0;
    }
    input[type="checkbox"]:checked+i{
        background: $primary-cl url(../img/check.svg) center center no-repeat;
        background-size: 13px 10px;
    }
    &.invert{
        i{
            background: transparent;
            border: 1px solid rgba($white-cl,0.5);
        }
        input[type="checkbox"]:checked+i{
            border-color: $primary-cl;
        }
    }
}
.form-sm{
    max-width: 400px;
}
.contacts-list,
.simple-content .contacts-list{
    li+li{
        margin-top: 10px;
    }
    .tel{
        color: $purple-cl;
    }
}
.radio-category{
    label{
        input[type="radio"]{
            display: none;
            &:checked+span{
                color: $primary-cl;
            }
        }
        span{
            display: block;
            transition: 0.2s ease;
        }
        cursor: pointer;
        display: inline-block;
        text-transform: uppercase;
        color: $white-cl;
        margin-right: 28px;
        white-space: nowrap;
        vertical-align: baseline;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    &.black{
        span{
            color: $purple-cl;
            white-space: normal;
        }
    }
}

.heading-categories{
    margin-bottom: 30px;
    margin-top: -10px;
    display: flex;
    align-items: center;
    position: relative;
    &__item{
        text-transform: uppercase;
        margin-right: 28px;
        cursor: pointer;
        transition:color 0.2s ease;
        &:hover{
            color: $grey;
        }
        &.active{
            color: $primary-cl;
        }
        &:last-child{
            margin-right:0;
        }
        @media(max-width: $breakpoint--phone){
            &+.heading-categories__item{
                margin-top: 15px;
            }
        }
    }
    @media (min-width: $breakpoint--normal + 10){
        margin-top: -80px;
        justify-content: flex-end;
        margin-bottom: 45px;
    }
    @media(max-width: $breakpoint--phone){
        flex-direction: column;
        align-items: flex-start;
    }
    label{
        &:last-child{
            margin-right:0;
        }
    }
    +.filter__row{
       margin-bottom: 40px;
    }
}


.check-drop{
    &__menu{
        text-align: left;
        background: #fff;
        border-radius: 6px !important;
        margin-top: 15px;
        box-shadow: 0 5px 20px rgba(0,0,0,0.08);
        padding: 30px 30px 10px;
        position: absolute;
        top:100%;
        left: 0;
        width: 100%;
        z-index: 3;
        display: none;
        .row{
            margin-right: -15px;
            margin-left: -15px;
            &>div{
            padding-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
            }
        }
        @media(max-width: $breakpoint--phablet){
            max-height: 300px;
            overflow-y: auto;
        }
    }
    .simple-checkbox{
        text-transform: none;
        margin: 0;
        i{
            background: #f2f2f5;
        }
    }
}

.select-input-wrap{
    .select2-container{
        width: 100% !important;
        background: #fff;
        padding: 0;
        border-radius: 30px;
    }
    .select2-container .select2-selection--single{
        padding: 15px 40px 15px 25px;
    }
    .select2-selection__rendered{
        text-transform: none;
    }
    .selection {
        display: block;
    }
    .select2-container--default .select2-selection--single .select2-selection__arrow {
        top: 18px;
        right: 16px;
    }
}