.acc__content{
  display: none;
}
.acc__toggle{
  cursor: pointer;
}
.accordion-list{
  .acc__toggle{
    padding-left: 28px;
    i{
      font-size: 10px;
      position: absolute;
      left: 0;
      top:14px;
      transition: 0.2s ease;
      @media(max-width: $breakpoint--phablet){
        top:10px;
      }
    }
    &.showed{
      i{
        transform: rotate(90deg);
      }
    }
  }
  &>li>.acc__content{
    font-family: $font-family !important;
    font-size: 15px;
    padding-left: 30px;
  }
  .acc__padding p{
    +p{
      margin-top: 20px;
    }
  }
}
.acc__padding{
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px;
  line-height: 30px;
  margin: 0;
  font-weight: 600;
  table{
    width: 100%;
    td{
      line-height: 24px;
      @media(max-width: $breakpoint--phone){
        font-size: 13px;
      }
    }
  }
}
.table-align-middle{
  td{
    vertical-align: middle;
  }
}