.bg{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    background: $purple-cl;
    &__container{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top:0;
        &:after{
            content: '';
            display: block;
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    &__container:not(.bg__container-video){
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            transform: none;
            transition: none;
        }
        @supports (-ms-ime-align:auto) {
            transform: none;
            transition: none;
        }
        transform: scale(1.1);
        transition: transform 10s ease-out 1.2s;
        .swiper-slide-active &{
            transform: scale(1);
        }
    }
    &__item{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        object-fit: cover;
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            max-width: none;
        }
        @supports (-ms-ime-align:auto) {
            max-width: none;
        }
    }
    &.bg__align-top{
        .bg__item{
            top: 0;
            transform: translate(-50%,0);
        }
    }
    &.bg__flipped{
        transform: scaleX(-1);
    }
    &__blured{
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            background: url(../img/ie-bg-blur.jpg) center center no-repeat;
            background-size: cover;
            height: 100%;
        }
        @supports (-ms-ime-align:auto) {
            background: url(../img/ie-bg-blur.jpg) center center no-repeat;
            background-size: cover;
            height: 100%;
        }
      .bg__item{
          width: 100%;
          min-height: 0;
          height: auto;
          transform: none;
          left:0;
          top:-50px;
          filter: blur(50px);
          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
              display: none;
          }
          @supports (-ms-ime-align:auto) {
              display: none;
          }
      }
    }
    img{
        transition: 0.6s ease-in-out;
    }
    &.darken{
        img{
            opacity: 0.6;
        }
    }
    &.moved{
        transform: translateY(-100px);
    }
    .video_bg{
        opacity: 0.5;
        @media(max-width: $breakpoint--phablet){
            display: none;
        }
    }
}
.mobile-video-poster{
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    object-fit: cover;
    opacity: 0.5;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: none;
    }
    @supports (-ms-ime-align:auto) {
        max-width: none;
    }
    @media(max-width: $breakpoint--phablet){
        display: block;
    }
}
.background-slider{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    .swiper-container{
        height: 100%;
    }
    .swiper-slide{
        overflow: hidden;
        .bg{
            height: 130%;
            left: 0;
            top: -15%;
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                transform: none !important;
                height: 100%;
                left: 0;
                top: 0;
            }
            @supports (-ms-ime-align:auto) {
                transform: none !important;
                height: 100%;
                left: 0;
                top: 0;
            }

        }
    }
}

iframe.video_bg{
    min-width: 150%;
    min-height: 150%;
}
.stud-main-bg-slider{
    height: 100%;
    .slick-list,
    .slick-track{
        height: 100%;
    }
    .slick-slide{
        &>div{
            height: 100%;
        }
        .sr-slide{
            height: 100%;
        }
    }
    .bg_student_item{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        max-width: 100%;
        object-fit: cover;
        opacity: 0.3;
    }
}