.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 0;
  margin-bottom: 25px;
  list-style: none;
}

.breadcrumb-item {
  font-size: 10px;
  text-transform: uppercase;
  color: rgba($white-cl, 0.35);
  margin-bottom: 5px;
  a{
    color: rgba($white-cl, 0.35);
    &:hover{
      color: $white-cl;
    }
  }
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  +.breadcrumb-item::before {
    content: '';
    display: inline-block; // Suppress underlining of the separator in modern browsers
    width: 2px;
    height: 2px;
    background: rgba($white-cl, 0.35);
    margin:2px 10px;
    vertical-align: middle;
  }

  // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
  // without `<ul>`s. The `::before` pseudo-element generates an element
  // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
  //
  // To trick IE into suppressing the underline, we give the pseudo-element an
  // underline and then immediately remove it.
  + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  // stylelint-disable-next-line no-duplicate-selectors
  + .breadcrumb-item:hover::before {
    text-decoration: none;
  }

  &.active {
    color: rgba($white-cl, 0.35);
  }
}
