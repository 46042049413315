.film-card-wide{
  position: relative;
  display: block;

  @media(min-width:$breakpoint--phone + 1){
    background: $purple-cl;

    &:hover{
      img{
        opacity: 0.5;
      }
    }
  }
  img{
    vertical-align: top;
    transition: 0.2s ease-in-out;
  }
  &__info{
    color: $purple-cl;
    width: 100%;
    span{
      display: block;
    }
    @media(min-width:$breakpoint--phone + 1){
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 2;
      color: $white-cl;
      padding:35px 120px 35px 40px;
    }
    @media(max-width:$breakpoint--phone){
      display: block;
      padding-top: 5px;
    }
  }
  .film-card__title{
    margin-bottom: 10px;
    @media(max-width:$breakpoint--phone){
      color: $purple-cl;
      margin-bottom: 5px;
    }
    @media(min-width:$breakpoint--phablet + 1){
      line-height: 2.7vw;
    }

  }
  .film-card__date{
    text-transform: uppercase;
    color: rgba($white-cl, 0.6);
    @media(max-width:$breakpoint--phone){
      color: rgba($purple-cl,0.5);
      font-size: 13px;
    }
    span{
      text-transform: uppercase;
    }
  }

}
.simple-play-button.small-right{
  @media(min-width:$breakpoint--phone + 1) {
    left: auto;
    top: auto;
    right: 40px;
    bottom: 35px;
    margin: 0;
  }
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  &:before{
    border: 8px solid transparent;
    border-left: 13px solid #fff;
    margin: -8px 0 0 -5px;
  }
}
.film-card__img{
  display: block;
  position: relative
}
.film-card-tall{
  .film-card__title{
    a{
      color: $purple-cl;
      &:hover{
        color: $primary-cl;
      }
    }
    margin-bottom: 8px;
  }
  .film-card__date{
    line-height: 20px;
    span{
      color:rgba($purple-cl,0.5);
      text-transform: uppercase;
      margin-right: 15px;
      white-space: nowrap;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .film-card__img{
    margin-bottom: 7px;
  }
}