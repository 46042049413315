.menu-fade-in{
  opacity: 0;
  transition: 0.3s ease 0s;
}
.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 100px 40px 0 40px;
  background: $purple-cl url(../img/menu_bg.jpg) center center no-repeat;
  background-size: cover;
  transform: translateY(-100%);
  //opacity: 0;
  flex-flow: wrap;
  transition: transform .7s ease-in-out, opacity .7s ease-in-out;
  -webkit-overflow-scrolling: touch;
  .logo-top{
    left: 55px;
    @media(max-width: $breakpoint--normal){
      left: 40px;
      top: 40px;
    }
    @media(max-width: $breakpoint--phone){
      left: 25px;
      top: 25px;
    }
  }
  .toggle-menu{
   right: 55px;
   @media(max-width: $breakpoint--normal){
     right: 40px;
     top: 40px;
   }
   @media(max-width: $breakpoint--phone){
     right: 25px;
     top: 25px;
   }
 }
  .soc-round{
    margin-bottom: 0;
  }
  .toggle-search{
    left: 10px;
    top: 0;
    opacity: 1;
    position: absolute;
    height: 50px;
  }
  @media (max-width: $breakpoint--normal){
    padding: 100px 40px 0 40px;
  }
  @media (max-width: $breakpoint--tablet){
    flex-flow: wrap;
    padding: 120px 40px 0 40px;
  }
  @media (max-width: $breakpoint--phablet){
    padding: 80px 25px 0 25px;
  }
  &.show {
    transform: translateX(0);
    opacity: 1;
    .menu-fade-in{
      opacity: 1;
      transition: 0.5s ease-in-out 0.7s;
    }
}
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 75%;
    height: auto;
    width: 100%;
    z-index: 2;
    @media (max-width: $breakpoint--tablet){
      height: auto;
    }
   }
  &__row {
    display: flex;
    margin: 0 -25px;
    padding-top: 40px;

      @media(max-width: $breakpoint--tablet){
        flex-wrap: wrap;
      }
      @media (max-width: $breakpoint--phablet) {
        padding-top: 35px;
      }
    }
  &__logo{
    position: absolute;
    top:36px;
    left:170px;
    @media (max-width: $breakpoint--normal){
      left: 140px;
    }
    @media (max-width: $breakpoint--phablet){
      left: 30px;
    }
  }
  &__lang{
    display: none;
    @media(max-width: $breakpoint--phablet){
       display: block;
       margin-bottom: 30px;
    }
  }
  &__item {
    width: 25%;
    padding: 0 25px;
    @media(max-width: $breakpoint--tablet){
      width: 50%;
      margin-right: 0;
      margin-bottom: 25px;
    }
    @media(max-width: $breakpoint--phablet){
      width: 100%;
    }
    &-home{
      @media (max-width: $breakpoint--normal){display: none;}
    }
   }
  &__item:first-child &__title:before {
    display: none; }
  &__title {
    position: relative;
    min-height: 70px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.8px;
    line-height: (17/14);
    color: $purple-cl;
    @media(max-width: $breakpoint--tablet){
     min-height: 10px;
    }
    @media(max-width: $breakpoint--phablet){
      line-height: 1.5;
    }
    a{
      color: $purple-cl;
      &:hover{
        color: $primary-cl;
      }
    }
    br {
      @media(max-width: $breakpoint--tablet){
        display: none;
      }
      }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 37px;
      height: 2px;
     } }
  &__list {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
  }
  &__link {
    font-size: 15px;
    font-weight: 600;
    line-height: 1.6;
    transition: color .2s;
    color: rgba($white-cl, 0.65);
    text-transform: uppercase;
    &:not(:last-child) {
      margin-bottom: 28px;
      @media (max-width: $breakpoint--phablet) {
        margin-bottom: 20px;
      }
    }
    &:hover {
      color: $primary-cl}
    @media(max-width: 370px){
      font-size: 13px;
    }
  }
  &__foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    width: 100%;
    min-height: 50px;
    position: relative;
    margin-top: 30px;
    padding-bottom: 50px;
    &-item {
      display: flex;
      align-items: center;
      .btn{
        vertical-align: top;
      }
    }
    .social-links{
      padding-right: 25px;
      @media(max-width: $breakpoint--phablet){
        padding-left: 25px;
        padding-right: 0;
        margin-top: 25px;
      }
      @media(max-width: 370px){
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        text-align: center;
      }
    }
    @media(max-width: $breakpoint--phablet){
      align-items: flex-start;
      flex-direction: column;
    }
  }
  &__search{
    width: 50px;
    height: 50px;
    margin-right: 25px;
    @media(max-width: $breakpoint--phone){
      position: static;
    }
  }
}
.menu-divider{
  border: none;
  outline: none;
  height: 1px;
  margin: 0 25px;
  border-top: 1px solid rgba($white-cl,0.5);
  display: block;
}

ul.menu-tabs {
  margin: 0 -25px;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;

  .icon-arr-right {
    display: none;
  }
}

ul.menu-tabs li {
  width: 25%;
  cursor: pointer;
  padding: 0 25px;
  color: rgba($white-cl,0.65);
  position: relative;
  font-family: $font-family-serif;
  text-transform: uppercase;
  font-size: 15px;
  transition: 0.3s ease;
  i{
    display: inline-block;
    vertical-align: middle;
    font-size: 9px;
    position: absolute;
    top: 4px;
    left: 4px;
    transform: rotate(90deg);
  }
  span{
    display: block;
    position: relative;
    padding-left: 23px;
    max-width: 200px;
  }
  @media(max-width: $breakpoint--tablet){
    font-size: 12px;
  }
}

.menu-tab_last { border-right: 1px solid #333; }

ul.menu-tabs li:hover {
   color: $primary-cl;
}

ul.menu-tabs li.active {
  color: $white-cl;
}

.menu-tab_container {
  clear: both;
  float: left;
  width: 100%;
  @media (max-width: $breakpoint--phablet) {
    margin-bottom: 35px;
  }
}

.menu-tab_content {
  padding: 10px 0;
  display: none;
  @media (max-width: $breakpoint--phablet) {
    padding-top: 0;
  }
  ul{
    list-style: none;
    padding: 0 0 0 25px;
    margin: 0;
  }
  li{
    margin-top: 25px;
    @media (max-width: $breakpoint--phablet) {
      margin-top: 20px;
    }
  }
  a{
    color: $white-cl;
    font-size: 15px;
    line-height: 20px;
    &:hover{
      color: $primary-cl;
    }
    @media(max-width: 370px){
      font-size: 13px;
    }
  }
  .col-submenu{
    padding-bottom: 35px;
    @media (max-width: $breakpoint--phablet){
      padding-bottom: 0;
    }
  }
}
.menu-tab_drawer_heading { display: none; }

@media (max-width: $breakpoint--phablet) {
  .menu__search .foot-item{
    display: block !important;
  }
  ul.menu-tabs {
    display: none;
  }
  .menu-tab_drawer_heading {
    color: rgba($white-cl, 0.5);
    display: block;
    cursor: pointer;
    padding: 0 25px;
    font-size: 15px;
    margin-top: 20px;
    text-transform: uppercase;
    position: relative;
    i {
      display: inline-block;
      vertical-align: middle;
      font-size: 9px;
      position: absolute;
      top: 4px;
      left: 4px;
      transform: rotate(90deg);
    }
  }
  .d_active {
    color: $white-cl;
  }
}
@media(max-width: 370px){
  .menu-tab_drawer_heading{
    font-size: 13px;
  }
}

.menu-lists{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
  ul{
    list-style: none;
    padding: 0;
  }
  &__item{
    width: 25%;
    padding: 0 25px;
    margin-bottom: 45px;

    @media (max-width: $breakpoint--tablet){
      width: 50%;
    }
    @media (max-width: $breakpoint--phablet){
      width: 100%;
    }
    &>a{
      color: rgba($white-cl,0.5);
      position: relative;
      font-family: $font-family-serif;
      text-transform: uppercase;
      font-size: 15px;
      transition: 0.3s ease;
      &:hover{
        color: $white-cl;
      }
      &.active{
        color: $white-cl;
      }
      i{
        display: inline-block;
        vertical-align: middle;
        font-size: 9px;
        position: absolute;
        top: 4px;
        left: -20px;
        transform: rotate(90deg);
      }
    }
    ul{
      li{
        margin-top: 25px;
        @media (max-width: $breakpoint--phablet) {
          margin-top: 20px;
        }
        ul{
          padding-left: 20px;
        }
      }
      .sub_head{
        position: relative;
        padding-left: 20px;
        i{
          display: inline-block;
          vertical-align: middle;
          font-size: 9px;
          position: absolute;
          top: 4px;
          left: 0;
          transform: rotate(90deg);
        }
      }
      a{
        color: $white-cl;
        font-size: 15px;
        line-height: 20px;
        &:hover{
          color: $primary-cl;
        }
        &.active{
          color: $primary-cl;
        }
        @media(max-width: 370px){
          font-size: 13px;
        }
      }
    }
  }
}