//article
.article{
  font-size: 15px;
  color: #060C2F;
  text-transform: uppercase;
  &__thumb{
    display: block;
    margin-bottom: 30px;
    img{
      vertical-align: top;
    }
    @media(max-width: $breakpoint--phone){
      margin-bottom: 20px;
    }
  }
  &__da{
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @media(max-width: $breakpoint--phablet){
      font-size: 13px;
    }
  }
  .date{margin-right: 30px;
    &--calendar {
      color: rgba($purple-cl,0.5);
      padding-left: 25px;
      background: url("../img/calendar.svg") 0 0 no-repeat;
      background-size: 16px 18px;
      min-height: 18px;
      margin-bottom: 6px;
      margin-right: 0;
    }
  }
  .author{
    display: flex;
    align-items: center;
  }
  .author-link{
    display: flex;
    align-items: center;
    span+span{
      margin-left: 10px;
    }
    img{
      width: 30px;
      height: 30px;
      display: block;
      border-radius: 50%;
    }
  }
  .name{
    font-family: $font-family-serif;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    text-transform: initial;
    @media(max-width: $breakpoint--phablet){
      font-size: 24px;
    }
  }
  .where{
    color:$primary-cl;
    @media(max-width: $breakpoint--phablet){
      font-size: 13px;
    }
  }
  .tag-link{
    color: $primary-cl;
    text-decoration: none;
    transition: 0.2s ease;
    cursor: pointer;
    &:hover{
      color: $purple-cl;
    }
  }
  .by{
    margin-right: 10px;
    @media(max-width: $breakpoint--phone){
      display: none;
    }
  }
}
.article-preview{
  +.article-preview{
    margin-top: 60px;
    @media(max-width: $breakpoint--phone){
      margin-top: 60px;
    }
  }
}