.error-page{
  display: table;
  height: 100vh;
  width: 100%;
  position: relative;
  color: #fff;
  text-align: center;
  background:#1b224e url(../img/aud-sketch_white.png) bottom center no-repeat;
  background-size: contain;
  &__logo{
    position: absolute;
    left: 40px;
    top:50px;
    opacity: 0.5;
    @media(max-width: $breakpoint--phone){
      left: 25px;
      top: 25px;
    }
  }
  &__wrap{
    display: table-cell;
    padding: 150px 30px;
    vertical-align: middle;
    @media(max-width: $breakpoint--phone){
     padding: 100px 20px;
    }
  }
  &__code{
    font-size: 200px;
    font-weight: 800;
    line-height: 190px;
    margin-bottom: 35px;
    @media (max-width: $breakpoint--phablet){
      font-size: 100px;
      line-height: 100px;
    }
  }
  &__center{
    padding-bottom: 50px;
    h2{
      font-size: 50px;
      font-weight: 800;
      @media (max-width: $breakpoint--phablet){
        font-size: 30px;
      }
    }
  }
}