.inst-feed{
  &-userpic{
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    padding-top: 13px;
    background: #e09b3d;
    background: -moz-linear-gradient(top, #e09b3d 0%, #c74c4d 35%, #c21975 65%, #7024c4 100%);
    background: -webkit-linear-gradient(top, #e09b3d 0%,#c74c4d 35%,#c21975 65%,#7024c4 100%);
    background: linear-gradient(to bottom, #e09b3d 0%,#c74c4d 35%,#c21975 65%,#7024c4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e09b3d', endColorstr='#7024c4',GradientType=0 );
    &.fb{
      background: #3B5998;
    }
    &.tw{
      background: #55ACEE;
      padding-top: 16px;
    }
    img{
      width: auto !important;
      vertical-align: top;
    }
  }
  &-profile{
    display: flex;
    align-items: center;
  }
  &-link{
    padding-left: 10px;
    span{
      display: block;
      +span{
        margin-top: 5px;
      }
    }
  }
}
.inst-feed-date{
  margin-bottom: 10px;
  color: $grey;
}
.inst-feed-card{
  &__title{
    font-size: 20px;
    font-family: $font-family-serif;
    margin-bottom: 20px;
  }
  &__img{
    margin-bottom: 20px;
    a{
      display: block;
    }
    img{
      vertical-align: top;
    }
  }
  +.inst-feed-card{
    margin-top: 50px;
    @media (max-width: $breakpoint--phablet) {
      margin-top: 40px;
    }
  }
}
.instagram-feed-container{
    position: relative;
   .inst-feed-follow{
     display: flex;
     align-items: center;
     margin-bottom: 30px;
     @media (min-width: $breakpoint--phone + 1){
       position: absolute;
       right: 0;
       top:-78px;
       margin-bottom: 0;
     }
     i{
       font-size: 9px;
       margin-left: 10px;
     }
     &:hover{
       color: $purple-cl;
     }
   }
}