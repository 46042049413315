.session-list{
    font-size: 15px;
    &__item{
        display: flex;
        line-height: 20px;
        margin-bottom: 30px;
        .day{
            min-width: 150px;
            @media(max-width: $breakpoint--phone){
               color: rgba($purple-cl,0.5);
            }
            &+span{
                @media(min-width: $breakpoint--phone + 1){
                    padding-left: 50px;
                }
            }
        }
        @media(max-width: $breakpoint--phone){
            flex-direction: column;
        }
    }
}
.session-footer{
    span{
        display: block;
        font-size: 15px;
        line-height: 20px;
        margin: 0;
        color: rgba($purple-cl,0.5);
        +span{
            margin-top: 8px;
        }
    }
}
.session-title{
    font-weight: 800;
    margin-bottom: 30px;
    padding-top: 10px;
    &.mb20{
        margin-bottom: 20px;
    }
}