.in-touch{
  &__page{
    display: flex;
    align-items: stretch;
    background: #f8f8fa;
    @media(max-width: $breakpoint--tablet){
      flex-direction: column;
    }
  }
  &__col{
     width: 100%;
    .bg-block{
      height: 100%;
    }
    @media(min-width: $breakpoint--tablet + 1){
      width: 50%;
    }
    .inner-wrap{
      @media(max-width: $breakpoint--large){
        padding-left: 100px;
        padding-right: 100px;
      }
      @media(max-width: $breakpoint--normal){
        padding-left: 60px;
        padding-right: 60px;
      }
      @media(max-width: $breakpoint--tablet){
        padding-left: 45px;
        padding-right: 45px;
      }
      @media (max-width: $breakpoint--phone){
        padding-right: 25px;
        padding-left: 25px;
      }
    }
  }
  &__map{
    height: 400px;
    overflow: hidden;
    position: relative;
    @media (max-width: $breakpoint--phablet){
      height: 300px;
    }
    h2{
      position: relative;
      z-index: 2;
      color: $white-cl;
      font-weight: 800;
      margin-top: 180px;
      padding-left: 150px;
      padding-right: 150px;
      display: inline-block;
      vertical-align: top;
      @media(max-width: $breakpoint--large){
        padding-left: 100px;
        padding-right: 100px;
      }
      @media(max-width: $breakpoint--normal){
        padding-left: 60px;
        padding-right: 60px;
      }
      @media(max-width: $breakpoint--tablet){
        padding-left: 45px;
        padding-right: 45px;
        font-size: 50px;
        line-height: 50px;
      }
      @media (max-width: $breakpoint--phablet){
        font-size: 30px;
        line-height: 30px;
        margin-top: 120px;
      }
      @media (max-width: $breakpoint--phone){
         padding-right: 25px;
         padding-left: 25px;
      }
      &:after{
        content: '';
        display: block;
        width: 70px;
        height: 5px;
        background: $primary-cl;
        margin-top: 15px;
      }
    }
  }
}