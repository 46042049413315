.toggle-menu {
    display: block;
    width: 20px;
    height: 18px;
    cursor: pointer;
    position: absolute;
    right: 45px;
    top: 45px;
    z-index: 5;
    transition: .5s ease-in-out;
    &__txt {
        position: absolute;
        right: 100%;
        color: $white-cl;
        top: 2px;
        margin-right: 12px;
        font-size: 14px;
    }
    &.fix{
        position: fixed;
        right: 55px;
        @media(max-width: $breakpoint--normal){
            right: 40px;
            top: 40px;
        }
        @media(max-width: $breakpoint--phone){
            right: 25px;
            top: 25px;
        }
    }
    i{
        display: block;
        width: 16px;
        height: 2px;
        border-radius: 0;
        background: #fff;
        position: absolute;
        top: 8px;
        left: 50%;
        margin-left: -8px;
        transition: .5s ease-in-out;
    }
    &:before {
        content: '';
        display: block;
        width: 20px;
        height: 2px;
        border-radius: 0;
        background: #fff;
        position: absolute;
        top: 0;
        left: 0;
        transition: .5s ease-in-out;
    }
    &:after {
        content: '';
        display: block;
        width: 20px;
        height: 2px;
        border-radius: 0;
        background: #fff;
        position: absolute;
        top: 16px;
        left: 0;
        transition: .5s ease-in-out;
    }
    &.dark{
        i{background: $purple-cl}
        &:before{background: $purple-cl}
        &:after{background: $purple-cl}
    }
    &:hover{
       i{
           width: 20px;
           margin-left: -10px;
       }
    }
    &.active{
        i{
            opacity: 0;
        }
        &:before {
            transform: rotate(45deg);
            top:7px;
        }
        &:after {
            transform: rotate(-45deg);
            top:7px;
        }
    }
    @media(max-width: $breakpoint--normal){
        top: 42px;
    }
    @media(max-width: $breakpoint--phone){
        right: 25px;
        top: 25px;
    }
}
