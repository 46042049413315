//Icon Fonts

@font-face {
  font-family: 'aud-icons';
  src:  url('../fonts/aud-icons.eot?vphgee');
  src:  url('../fonts/aud-icons.eot?vphgee#iefix') format('embedded-opentype'),
  url('../fonts/aud-icons.ttf?vphgee') format('truetype'),
  url('../fonts/aud-icons.woff?vphgee') format('woff'),
  url('../fonts/aud-icons.svg?vphgee#aud-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'aud-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arr-long-left:before {
  content: "\e900";
}
.icon-arr-long-right:before {
  content: "\e901";
}
.icon-arr-long-up:before {
  content: "\e902";
}
.icon-arr-right:before {
  content: "\e903";
}
.icon-download:before {
  content: "\e904";
}
.icon-rss:before {
  content: "\e905";
}
.icon-back:before {
  content: "\e906";
}
.icon-nav-left:before {
  content: "\e907";
}
.icon-nav-right:before {
  content: "\e908";
}
.icon-star:before {
  content: "\e909";
}
.icon-calendar-end:before {
  content: "\e90a";
}
.icon-calendar-start:before {
  content: "\e90b";
}
.icon-pin:before {
  content: "\e90c";
}
.icon-eye:before {
  content: "\e90d";
}
.icon-facebook:before {
  content: "\e90e";
}
.icon-twitter:before {
  content: "\e90f";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-author:before {
  content: "\e911";
}
.icon-play:before {
  content: "\e912";
}
.icon-clock:before {
  content: "\e913";
}