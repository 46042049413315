 $primary-cl: #F3223C;
 $error-cl:#f15b4e;
 $grey-cl: #2d2b50;
 $white-cl: #fff;
 $light-grey: #b2b1b7; /* #cacad3 */
 $border-grey: #eae9ed;
 $purple-cl: #1b224e;
 $dark-blue-cl:#060C2F;
 $grey:#7E8194;


 $breakpoint--large: 1399px;
 $breakpoint--normal: 1199px;
 $breakpoint--tablet: 991px;
 $breakpoint--phablet: 767px;
 $breakpoint--phone: 520px;

 $font-base: 14px;
 $font-10: 10px;
 $font-12: 12px;
 $font-14: 14px;
 $font-16: 16px;
 $font-18: 18px;
 $font-20: 20px;
 $font-24: 24px;
 $font-28: 28px;
 $font-30: 30px;
 $font-36: 36px;
 $font-38: 38px;
 $font-48: 48px;
 $font-60: 60px;
 $font-80: 80px;
 $font-72: 72px;
 $font-100: 100px;
 $font-120: 120px;
 $font-150: 150px;
 $font-family: 'Raleway', sans-serif;
 $font-family-serif: 'Crimson Text', serif;
 //$font-family-appex:'ApexNew';

 $line-heaight: 1;
 $lh-big: 40px;

 $anim-02: 0.2s ease-in-out;
 $anim-04: 0.4s ease-in-out;
