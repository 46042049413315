.pw{
  &__card{
    position: relative;
    display: block;
    margin-bottom: 40px;
    &:hover .pw__img img{
      opacity: 0.6;
    }
    &:last-child{
      margin-bottom: 20px;
    }
  }
  &__caption{
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 110px 40px 40px;
    @media(max-width: $breakpoint--phablet){
      padding: 0 90px 30px 30px;
    }
    @media(max-width: $breakpoint--phone){
      position: static;
      color: $purple-cl;
      padding: 20px 0 0 0;
    }
  }
  &__img{
    display: block;
    position: relative;
    background: $purple-cl;
    img{
      transition: 0.2s ease;
      vertical-align: top;
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 50%;
      left: 0;
      bottom: 0;
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(17,28,97,0.6) 100%);
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(17,28,97,0.6) 100%);
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(17,28,97,0.6) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#99111c61',GradientType=0 );
      @media(max-width: $breakpoint--phone){
        display: none;
      }
    }
  }
  &__photo{
    width: 50px;
    height: 50px;
    img{
      border-radius: 50%;
    }
  }
  &__info{
    padding-left: 20px;
  }
  &__title{
    display: block;
    margin-bottom: 12px;
    line-height: 20px;
  }
  &__name{
    display: block;
  }
  .simple-play-button.small-right{
    @media (min-width: $breakpoint--phone) and (max-width: $breakpoint--phablet){
      right: 30px;
      bottom: 30px;
    }
  }
}