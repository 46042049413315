.sticked-nav{
    position: fixed;
    top:-200px;
    opacity: 0;
    transition: 1s ease-in-out 0s;
    width: 100%;
    z-index: 3;
    padding-right: 150px;
    padding-left: 205px;
    &.visible{
        top:45px;
        opacity: 1;
        @media (max-width: $breakpoint--normal){
            top:40px;
        }
        transition: 1s ease-in-out 0s;
    }
    .sub-title{
        font-size: 18px;
        line-height: 24px;
        padding: 0 20px;
        opacity: 1;
        transform: none !important;
        transition: 0.3s ease-in-out !important;
    }

    @media (max-width: $breakpoint--tablet){
        display: none;
    }
}