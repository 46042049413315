.profile-card{
  position: relative;
  z-index:2;
  @media(min-width: $breakpoint--tablet + 1) {
    padding-right: 160px;
  }
  &__top{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    @media(max-width: $breakpoint--phablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &__bottom{
    @media(min-width: $breakpoint--phablet + 1) {
      display: flex;
      align-items: center;
    }
  }
  &__photo{
    min-width: 270px;
    @media(max-width: $breakpoint--phablet) {
      min-width: 140px;
      margin-bottom: 30px;
    }
    img{
      width: 270px;
      border-radius: 50%;
      vertical-align: top;

      @media(max-width: $breakpoint--phablet) {
        max-width: 140px;
      }
    }
  }
  &__stat{
    min-width: 270px;
    @media(max-width: $breakpoint--phablet) {
      min-width: 140px;
    }
  }
  &__info{
    padding-left: 60px;
    @media(max-width: $breakpoint--phablet) {
      padding-left: 0;
    }
  }
  &__list{
    display: flex;
  }
  &__list-item{
    padding-right: 55px;
    &:last-child{
      padding-right: 0;
    }
    small{
      font-size: 15px;
      margin-bottom: 5px;
    }
  }
  &__desc{
    font-size: 20px;
    line-height: 35px;
    padding-left: 60px;
    margin-top: -80px;

    @media(max-width: $breakpoint--tablet){
      font-size: 18px;
      margin-top: -35px;
    }

    @media(max-width: $breakpoint--phablet) {
      margin-top: 0;
      padding-left: 0;
      padding-top: 40px;
    }
  }
}
.profile__nav{
  margin-top: 40px;
  height: 100px;
  .swiper-container,
  .slick-slider{
    height: 100%;
  }
  .slick-slide{
    outline: none;
  }
  @media(min-width: $breakpoint--tablet + 1) {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    width: 70px;
    margin-top: 0;
  }

}
.progress{
  &__label{
    display: block;
    margin-bottom: 5px;
    font-size: 15px;
  }
  &__bar{
    position: relative;
    background: rgba(255,255,255,0.2);
    height: 5px;
    border-radius: 3px;
  }
  &__fill{
    position: absolute;
    height: 100%;
    background: $primary-cl;
    top:0;
    left:0;
    max-width: 100%;
    border-radius: 3px;
  }
  &+&{
    margin-top: 20px;
  }
}
.nav__circle{
  width: 70px;
  height: 70px;
  border-radius: 50%;
  cursor: pointer;
  img{
    width: 70px;
    border-radius: 50%;
    vertical-align: top;
  }
}
.swiper-slide-active .nav__circle,
.slick-current .nav__circle{
  background: $primary-cl;
  img{opacity: 0.45}
}
.sr-slide{
  outline: none;
  padding: 10px 0;
}