.map{
  display: inline-block;
  vertical-align: top;
  img{
    vertical-align: top;
    max-width: 100%;
  }
  &__container{
    @media(max-width: 620px){
      margin-bottom: 25px;
    }
  }
  &__title{
    margin: 0 0 5px;
    font-size: 20px;
    @media(max-width: 620px){
       text-align: center;
    }
  }
  &__info{
    display: flex;
    align-items: center;
    @media(max-width: 620px){
      justify-content: center;
    }
  }
  &__stat{
    position: absolute;
    left: 20px;
    bottom: 20px;
    @media(max-width: 620px){
      margin-top: 0;
      position: static;
    }
  }
}
.svg-map{
  //max-width: 100%;
  //height: auto !important;
  //width: 100% !important;
  display: block;
  .inner_pin {
    transform: scale(1);
  }
  @media (max-width: 767px){
    max-width: 100%;
    height: auto !important;
    width: 100% !important;
  }
  @media (min-width: 768px){
    width: 670px;
    height: 307px;
    margin: -20px 0;
    .inner_pin {
      transform: scale(0.7) translate(5px, 10px);
    }
  }
  @media (min-width: 992px){
    width: 870px;
    height: 507px;
    margin: -40px 0;
    .inner_pin {
      transform: scale(0.8) translate(3px, 6px);
    }
  }

}
.inner_pin{
  transition: 0.2s ease;
  &:hover,
  .current &{
    fill:$primary-cl;
  }
}
.country-desc{
  display: none;
}