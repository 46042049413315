.video-block{
  .video{
    width: 480px;
    height: 480px;
    border-radius: 240px;
    overflow: hidden;
    margin: 0 auto 50px;
    position: relative;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    img{
      max-width: 100%;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
    }
    @media (max-width: $breakpoint--phablet){
      width: 240px;
      height: 240px;
    }
  }
}
.videoWrapper {
  position: relative;
  width: 100%;
  height: 0;
  background-color: #000;
}
.videoWrapper169 {
  padding-top: 56%;
}

.videoIframe{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  opacity: 0;
}
.modalVideoIframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.videoPoster {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  background:$purple-cl;
  text-indent: -999em;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity 800ms, height 0s;
  -moz-transition: opacity 800ms, height 0s;
  transition: opacity 800ms, height 0s;
  -webkit-transition-delay: 0s, 0s;
  -moz-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
   &>img{
     position: absolute;
     left: 50%;
     top:50%;
     transform: translate(-50%,-50%);
     min-width: 100%;
     min-height: 100%;
   }
  .videoWrapperActive & {
    opacity: 0;
    height: 0;
    -webkit-transition-delay: 0s, 800ms;
    -moz-transition-delay: 0s, 800ms;
    transition-delay: 0s, 800ms;
  }
}
.videoWrapperActive .videoIframe{
  opacity: 1;
}
.close-icon{
  background:#494a4c;
  background-size: 16px 16px;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 8px;
  right:10px;
  display: none;
  border-radius: 5px;
  img{
    width: 14px !important;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -7px 0 0 -7px;
  }
}
.videoWrapperActive .close-icon{
  display: block;
}

body+div{
  display: none !important;
}