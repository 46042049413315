.section{
  position: relative;
  transition: 600ms ease-in-out;
  &__caption{
    position: relative;
    z-index: 2;
    &.w80{
      @media (min-width: $breakpoint--tablet + 1) {
        max-width: 80%;
      }
    }
  }
  .tbl{
    //height: 100vh;
    height: 100%;
  }
  &.darken{
    background: rgba($purple-cl, 0.75);
  }
}
.tbl{
  display: table;
  width: 100%;
}
.tbl-cell{
  display: table-cell;
  vertical-align: middle;
  padding: 70px 40px;
  @media (min-width: $breakpoint--normal + 1) {
    padding: 80px 40px;
  }
  @media(max-width: $breakpoint--phone){
    padding: 80px 25px 50px;
  }
}
.tbl-resp{
   @media (max-width: $breakpoint--tablet){
     display: block;
     height: auto !important;
     .tbl-cell{
       display: block;
     }
   }
}