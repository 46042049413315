.logo-top{
    position: absolute;
    top: 45px;
    left: 40px;
    z-index: 5;
    width: 139px;
    transition: 0.5s ease-in-out;
    &.fix{
        position: fixed;
        left: 55px;
        z-index: 5;
        @media(max-width: $breakpoint--normal){
            left: 40px;
            top: 40px;
        }
        @media(max-width: $breakpoint--phone){
            left: 25px;
            top: 25px;
        }
    }
    &.head-item.mob-faded{
        @media(max-width: $breakpoint--tablet){
            opacity: 0;
        }
    }
    img{
        vertical-align: top;
        opacity: 0;
        transition: 0.5s ease-in-out;
    }
    .logo-w{
        opacity: 1;
        position: absolute;
        left:0;
        top:0;
        width: 100%;
    }
    &.dark{
        img{
            opacity: 1;
        }
        .logo-w{
            opacity: 0;
        }
    }
    @media(max-width: $breakpoint--normal){
        top: 40px;
    }
    @media(max-width: $breakpoint--phone){
        left: 25px;
        top: 25px;
    }
}
.head-item{
    transition: .5s ease-in-out;
    &.faded{
        opacity: 0;
    }
}
.foot-item{
    transition: .5s ease-in-out;
    &.faded{
        opacity: 0;
    }
}
.foot-item.toggle-search{
    @media(max-width: $breakpoint--phablet){
        display: none !important;
    }
}
.apply__btn-wrap {
    position: fixed;
    bottom: 30px;
    z-index: 4;
    left: 105px;
    .btn{
        vertical-align: top;
    }
    @media(max-width: $breakpoint--normal){
        left: 105px;
        bottom: 25px;
    }
    @media(max-width: $breakpoint--phablet){
        //display: none !important;
       left: 25px;
    }
}

.logo-inner{
    margin-bottom: 25px;
    display: block;
}
.logo-inner-mob{
    display: none;
    @media(max-width: $breakpoint--tablet){
        display: block;
    }
}