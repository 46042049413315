.inner-container{
  background: #f8f8fa;
  position: relative;
  @media(min-width: $breakpoint--tablet + 1){
    &.no-spacing{
      padding-left:0;
      &:before{
        display: none;
      }
    }
    padding-left:300px;
    //min-height: 100vh;
    &:before{
      content: '';
      display: block;
      width: 300px;
      height: 100%;
      position: absolute;
      background: $white-cl;
      left: 0;
      top:0;
    }
  }

}
.general-container{
  background: #f8f8fa;
}
.inner-wrap{
   max-width: 980px;
   margin: 0 auto;
  padding: 100px 150px;
  @media (max-width: $breakpoint--normal){
    padding: 80px;
  }
  @media (max-width: $breakpoint--phablet){
    padding:60px 40px;
  }
  @media (max-width: $breakpoint--phone){
    padding:40px 25px;
  }

  &.single-component-wrap{
    padding-top: 25px;
    padding-bottom: 25px;
    .hero__block+.inner-container &{
      padding-top: 100px;
      @media (max-width: $breakpoint--normal){
        padding-top: 80px;
      }
      @media (max-width: $breakpoint--phablet){
        padding-top: 60px;
      }
      @media (max-width: $breakpoint--phone){
        padding-top: 40px;
      }
    }
    .inner-container:last-child &{
      padding-bottom: 100px;
      @media (max-width: $breakpoint--normal){
        padding-bottom: 80px;
      }
      @media (max-width: $breakpoint--phablet){
        padding-bottom: 60px;
      }
      @media (max-width: $breakpoint--phone){
        padding-bottom: 40px;
      }
    }
    .hero__sidebar + .inner-container &{
      padding-top: 100px;
      @media (max-width: $breakpoint--normal){
        padding-top: 80px;
      }
      @media (max-width: $breakpoint--phablet){
        padding-top: 60px;
      }
      @media (max-width: $breakpoint--phone){
        padding-top: 40px;
      }
    }
  }
}
.inner-container + .hero__block{
   margin-top: 50px;
}
.simple-content{
  .dashed-title{
    font-size: 3.55vw;
    line-height: 3.75vw;
    &:after{
      content: '';
      display: block;
      width: 70px;
      height: 5px;
      background: $primary-cl;
      margin-top: 15px;
    }
    @media(max-width: $breakpoint--tablet){
      font-size: 4vw;
      line-height: 4.3vw;
    }
    @media(max-width: $breakpoint--phablet){
      font-size: 32px;
      line-height: 40px;
    }
    &:after{
      content: '';
      display: block;
      width: 70px;
      height: 5px;
      background: $primary-cl;
      margin-top: 15px;
    }
    @media(max-width: $breakpoint--tablet){
      font-size: 4vw;
      line-height: 4.3vw;
    }
    @media(max-width: $breakpoint--phablet){
      font-size: 32px;
      line-height: 40px;
    }
  }
  h3{
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    margin: 0 0 40px;
    @media (max-width: $breakpoint--phone){
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 30px;
    }
    &:after{
      content: '';
      display: block;
      width: 70px;
      height: 5px;
      background: $primary-cl;
      margin-top: 15px;
    }
  }
  img{
    max-width: 100%;
    width: 100%;
    &.auto-width{
      width: auto;
    }
  }
  p{
    font-size: 15px;
    line-height: 30px;
    margin: 0;
    font-weight: 600;
    a{
      color: $primary-cl;
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
  }
   li{
     a:not(.p-text){
       color: $primary-cl;
       font-size: 15px;
       line-height: 20px;
       font-weight: 600;
       text-decoration: none;
       &:hover{
         text-decoration: underline;
       }
     }
   }
  ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    &>li{
      font-size: 15px;
      line-height: 25px;
      font-weight: 600;
      padding-left: 30px;
      position: relative;
      &::before{
        content: '';
        width: 5px;
        height: 5px;
        background: $primary-cl;
        position: absolute;
        top: 10px;
        left: 0;
      }
      ul {
        margin-top: 15px;
      }
    }
    &>li+li{
      margin-top: 15px;
    }
    a:hover{
      color: $primary-cl;
    }
    &.no-bullets{
      li{
        padding-left: 0;
        &:before{
          display: none;
        }
      }
    }
  }

  ol{
    list-style-type: none;
    counter-reset: section;
    padding: 0;
    margin: 0;
    &>li{
      font-size: 15px;
      line-height: 25px;
      font-weight: 600;
      position: relative;
      &::before{
        display: inline-block;
        vertical-align: baseline;
        counter-increment: section;
        content: counter(section)".";
        color: $primary-cl;
        position: relative;
        margin-right: 6px;
        min-width: 24px;
      }
      ul,ol{
        //padding-left: 30px;
        margin-top: 15px;
      }
    }
    &.ol-roman{
      &>li{
        &::before{
          display: inline-block;
          vertical-align: baseline;
          counter-increment: section;
          content: counter(section, upper-roman);
          color: $primary-cl;
          position: relative;
          margin-right: 6px;
          min-width: 24px;
        }
      }
    }
    &>li+li{
      margin-top: 15px;
    }
    a:hover{
      color: $primary-cl;
    }
    &.no-bullets{
      li{
        padding-left: 0;
        &:before{
          display: none;
        }
      }
    }
  }

  ol.big-margin,
  ul.big-margin{
    &>li+li{
      margin-top: 50px;
    }
  }
  ol.no-nums{
    &>li:before{
      display: none;
    }
  }
  >.row + h3{
    margin-top: 50px;
  }
  >ul+h3,
  >ol+h3,
  >p+h3{
    margin-top: 65px;
    //margin-top: 100px;
    @media only screen and (max-width: $breakpoint--phone) {
      margin-top: 40px;
    }
  }
  >img+p,
  >p+*,
  >ul+*,
  >ol+*{
    margin-top: 30px;
  }
  *+blockquote{
    margin-top: 40px;
  }
  blockquote+*{
    margin-top: 40px;
  }
  .simple-img-carousel+*{
    margin-top:20px;
  }
  *+.simple-video,
  *+.simple-img-carousel{
    margin-top: 50px;
  }
  .simple-video+*,
  .simple-img-carousel+*{
    margin-top: 50px;
  }
  .simple-video-titled{
    .simple-video+*{
      margin-top: 18px;
    }
  }
  .simple-video-titled+*{
    margin-top: 50px;
  }
  b,strong{
    font-weight: 800;
  }



  .list-links{
    &>li>div,
    &>li>a{
      font-family: $font-family-serif;
      font-size: $font-30;
      line-height: 1;
      color: $dark-blue-cl;
      @media screen and ($breakpoint--normal){
        font-size: $font-24;
      }
      @media (max-width: $breakpoint--phablet) {
        font-size: 24px;
      }
    }
    >li+li{
      margin-top: 20px;
    }
    a:hover{
      color: $primary-cl;
    }
  }

  .list-links-tabs{
    column-count: 1;
  }

  .list-section{
    li{
      h4{
        font-size: 20px;
        font-weight: 800;
        line-height: 30px;
        margin-bottom: 10px;
      }
    }
    li+li{
      margin-top: 35px;
    }
    &__links{
      display: flex;
      flex-wrap: wrap;
      a{
        text-transform: uppercase;
        font-size: 15px;
        line-height: 20px;
        color:  $grey;
        +a{
          margin-left: 20px;
        }
      }
    }
  }
  blockquote{
    font-family: $font-family-serif;
    margin-left: 0;
    margin-right: 0;
    font-size: 2.5vw;
    line-height: 3.7vw;
    @media (min-width: $breakpoint--large + 1){
      font-size: 30px;
      line-height: 45px;
    }
    @media(max-width: $breakpoint--phablet){
      font-size: 28px;
      line-height: 36px;
    }
    @media (max-width: $breakpoint--phone) {
      font-size: 24px;
      line-height: 30px;
    }
    text-align: center;
    &:before{
      content: '';
      display: block;
      background-image: url("../img/blockquote.svg");
      width: 46px;
      height: 32px;
      margin: 0 auto 15px;
    }
  }
}
.simple-video{
  position: relative;
  background: $purple-cl;
  cursor: pointer;
    img{
    width: 100%;
    vertical-align: top;
    transition: 0.3s ease;
  }
  .simple-play-button{
    box-shadow: 0 10px 40px rgba($purple-cl, 0.1);
    transition: 0.3s ease;
  }
  &:hover {
    img{
      opacity: 0.6;
    }
    .simple-play-button{
      box-shadow: 0 10px 40px rgba($purple-cl, 0.5);
    }
  }

}
.simple-title{
  font-size: 42px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 35px;
  @media only screen and (max-width: $breakpoint--phablet) {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: $breakpoint--phone) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
  }
}
.simple-play-button{
  width: 80px;
  height: 80px;
  background: $primary-cl;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: 50%;
  top:50%;
  margin: -40px 0 0 -40px;
  z-index: 2;
  &:before{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 12px solid transparent;
    border-left:20px solid $white-cl;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -12px 0 0 -8px;
  }
}
.simple-img-carousel{
  &__title{
    margin-top: 20px !important;
  }
  img{
    width: 100%;
    vertical-align: top;
  }
  .slick-slide>div,
  .si-item{
    vertical-align: top;
  }
  &.slide-articles{
    .simple-carousel-nav{
     // font-size: 0;
      top: -75px;
      left: initial;
      bottom: initial;
      background: none;
      padding: 0;
      width: 16px;
      text-align: center;
      &.prev{
        right: 50px;
      }
      &.next{
      }
      i{
        margin: 0 auto;
        font-size: 11px;
      }
      &:hover{
        color: $primary-cl;
      }
    }
  }
}

.simple-carousel-nav{
  text-transform: uppercase;
  background: #fff;
  padding: 17px;
  z-index: 3;
  display: block;
  position: absolute;
  transition: 0.3s ease;
  cursor: pointer;
  &:hover{
    background: $primary-cl;
    color: $white-cl;
  }
  i{
    font-size: 8px;
    vertical-align: middle;
  }
  &.prev{
    left: 0;
    bottom:0;
    i{
      margin-left: 10px;
    }
  }
  &.next{
    top:0;
    right:0;
    i{
      margin-right: 10px;
    }
  }
  @media(max-width: $breakpoint--phone){
    padding: 10px;
    font-size: 13px;
  }
}
.simple-more{
  margin-top: 30px;
  a{
    text-transform: uppercase;
    font-size: 15px;
    color: $primary-cl;
    &:hover{
      color: $purple-cl;
    }
  }
  i{
    font-size: 8px;
    vertical-align: middle;
    margin-right: 12px;
  }
}



.venue{
  display: flex;
  justify-content: space-between;
  font-size: $font-30;
  color: $dark-blue-cl;
  font-family: $font-family-serif;
  font-weight: 600;
  line-height: 30px;
  .venue-column{
    display: flex;
    flex-direction: column;
    max-width: 200px;
    .venue-header{
      color: $light-grey;
      font-size: 15px;
      text-transform: uppercase;
    }
    @media(max-width: $breakpoint--phablet){
      +.venue-column{
        margin-top: 25px;
      }
    }
  }
  @media(max-width: $breakpoint--phablet){
    flex-direction: column;
  }
}

.simple-content  .read-about{
  font-family: $font-family-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
}

.date-about{
  font-size: 15px;
  text-transform: uppercase;
  color: rgba(6,12,46,.5);
}

.link-about{
  display: inline-block;
  color:$primary-cl;
  text-transform: uppercase;
  margin-bottom: 10px;
  &:last-of-type{
    margin-bottom: 40px;
  }
}

.accreditation{
  display: flex;
  margin-bottom: 80px;
  @media(max-width: $breakpoint--phablet){
    display: block;
  }
  .accreditation-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 170px;
    min-height: 170px;
    max-height: 170px;
    max-width: 170px;
    background: #ffffff;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 30px;

    img{
      vertical-align: top;
      width: initial;
      max-width: 100%;
    }
    @media(max-width: $breakpoint--phablet){
      margin: 0 0 20px;
    }
  }
  .accreditation-text{
    .name{
      font-size: $font-30;
      color: $dark-blue-cl;
      font-family: $font-family-serif;
      font-weight: 600;
      line-height: 30px;
      display: block;
      &:hover{
        color: $primary-cl;
      }
    }
    .contacts{
      margin-top: 10px;
      color: rgba($dark-blue-cl,.5);
      line-height: 20px;
      text-transform: uppercase;
    }
  }
  &:last-child{
    margin-bottom: 0;
    .accreditation-logo{
      background: #F0F0F0;
    }
  }
}

.img-job{
  .img{
    margin-right: 30px;
    max-width: 85px;
    border-radius: 50%;
    overflow: hidden;
    img{
      vertical-align: top;
      border-radius: 50%;
    }
  }

  .job-postition{
    color: rgba(6,12,46,.5);
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .name{
    font-size: $font-30;
    color: $dark-blue-cl;
    font-family: $font-family-serif;
    font-weight: 600;
    line-height: 30px;
  }
}
.bg-block{
  background-size: cover;
  background-position: center;
  position: relative;
  .inner-wrap{
    position: relative;
    z-index: 3;
  }
  .col-sm-6{
    padding-bottom: 0;
    @media (max-width: 575px){
      padding-top: 30px;
    }
  }
  &__txt{
    line-height: 30px;
    margin: -10px 0 30px;
  }
  &__ul{
    list-style-type: none;
    padding: 0;
    margin: 0 0 35px;
    li{
      font-size: 15px;
      line-height: 20px;
      font-weight: 600;
      padding-left: 30px;
      position: relative;
      &::before{
        content: '';
        width: 5px;
        height: 5px;
        background: $primary-cl;
        position: absolute;
        top: 9px;
        left: 0;
      }
    }
    li+li{
      margin-top: 10px;
    }
  }
  &.contacts{
    //background-image: url('../img/contact-bg.jpg');

  }
  h3{
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 800;
    margin: 0 0 40px;
    color: #ffffff;
    @media (max-width: $breakpoint--phone){
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 30px;
    }
    &:after{
      content: '';
      display: block;
      width: 70px;
      height: 5px;
      background: $primary-cl;
      margin-top: 15px;
    }
  }
  .email{
    text-transform: initial;
  }
  .p-text{
    text-transform: initial;
    font-size: 30px;
    line-height: 35px;
    margin-top: 5px;
    font-weight: 600;
    @media (max-width: $breakpoint--phablet){
      font-size: 24px;
      line-height: 30px;
    }
    +span{
      margin-top: 10px;
    }
  }
  .cont-col{
    padding-bottom: 0;
    .icon-clock{
      font-size: 20px;
      margin-right: 5px;
      vertical-align: baseline;
    }
  }
}
.inline-link{
  display: inline-block;
  vertical-align: middle;
  img{
    vertical-align: top;
  }
  &+&{
    margin-left: 42px;
  }
}
.resources-links{
  ul li{
    text-transform: uppercase;
    font-size: 15px;
    a{
      color: rgba($purple-cl, 0.5);
      &:hover{
        color: $primary-cl;
      }
    }
  }
  h5{
    font-size: 20px;
    font-weight: 800;
    margin: 0 0 25px;
    a{
      text-transform: none !important;
      color: $purple-cl !important;
      &:hover{
        color: $primary-cl !important;
      }
    }
  }
}
.sitemap-links{
  h5{
    margin-bottom: 0;
  }
  div>ul>li>a{
    color: rgba($purple-cl, 0.8);
    &:hover{
      color: $primary-cl;
    }
  }
}
.admission{
  display: flex;
  align-items: center;
  @media(max-width: $breakpoint--phablet){
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.admission-logo{
  min-width: 270px;
  img{
    border-radius: 50%;
  }
  @media(max-width: $breakpoint--phablet){
    margin-bottom: 35px;
    img{
      width: 135px;
    }
  }
}
.admission-text{
  padding-left: 30px;
  h3{
    margin-bottom: 30px;
  }
  p{
    max-width: 800px;
  }
  @media(max-width: $breakpoint--phablet){
    padding-left: 0;
  }
}
.simple-admission-carousel{
  .simple-carousel-nav{
    background: transparent;
    color: $primary-cl;
    &:hover{
      color: $purple-cl;
    }
    i{
      font-size: 9px;
      margin: 0;
    }
    &.next{
      left: 380px;
      right: auto;
      bottom: 30px;
      top: auto;
      @media(max-width: $breakpoint--phablet){
        left:50%;

      }
    }
    &.prev{
      left: 335px;
      bottom: 30px;
      right: auto;
      top: auto;
      @media(max-width: $breakpoint--phablet){
        left:50%;
        margin-left:-55px;
      }
    }
  }
  .si-item{
    padding: 50px 50px 80px 50px;
    @media(max-width: $breakpoint--phone){
      padding: 30px 25px 80px 25px;
    }
  }
}
.inner-wrap__full{
  padding: 30px 0;
}
.pv-col{
  padding-bottom: 10px;
  span{
    line-height: 20px;
  }
}
.heading-link{
  margin-top: -78px;
  text-align: right;
  margin-bottom: 60px;
  @media(max-width: $breakpoint--phone){
    margin-top: 0;
    text-align: left;
    margin-bottom: 30px;
  }
  a:hover{
    color: $purple-cl;
  }
}
.hero-contact-block .row,
.hero-invert-row{
    span{
      display: block;
      color: $white-cl;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 5px;
    }
    p{
      display: block;
      color: $white-cl;
      font-family: $font-family-serif;
      font-size: 2.5vw;
      line-height: 2.7vw;
      margin: 0 0 30px;
      &:last-child{
        margin-bottom: 0;
      }
      @media (min-width: $breakpoint--large + 1){
        font-size: 30px;
        line-height: 35px;
      }
      @media (max-width: $breakpoint--phablet) {
        font-size: 25px;
        line-height: 30px;
      }
      @media (max-width: $breakpoint--phone) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    a{
      display: inline-block;
      color: $primary-cl;
      vertical-align: baseline;
      font-family: $font-family-serif;
      font-size: 2.5vw;
      line-height: 2.7vw;
      margin: 0 0 30px;
      &:last-child{
        margin-bottom: 0;
      }
      @media (min-width: $breakpoint--large + 1){
        font-size: 30px;
        line-height: 35px;
      }
      @media (max-width: $breakpoint--phablet) {
        font-size: 25px;
        line-height: 30px;
      }
      @media (max-width: $breakpoint--phone) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    a[href^="tel:"] {
     color: $white-cl;
    }
}