.stud-inner-slider{
  .divider-dash{
    width: 70px;
    margin-bottom: 25px;
  }
  .profile-card__top,
  .profile-card__bottom{
    position: relative;
    z-index: 2;
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-card__photo{
    @media (min-width: $breakpoint--phablet + 1){
      width: 170px;
      height: 170px;
      min-width: 170px;
      img{
        width: 170px;
      }
    }
  }
  .profile-card__name{
    font-size: 30px;
    font-weight: 800;
  }
  .profile-card__info{
    padding-left: 30px;
    span{
      line-height: 20px;
      max-width: 330px;
    }
    @media (max-width: $breakpoint--phablet){
      padding-left: 0;
    }
  }
  .profile-card__bottom{
    p{
      margin: 0;
      font-size: 20px;
      line-height: 35px;
      @media (max-width: $breakpoint--phablet){
        font-size: 15px;
        line-height: 25px;
      }
    }
  }
  .sr-slide{
    padding: 70px 30px 205px;
    @media (max-width: $breakpoint--phablet){
      padding: 45px 30px 150px;
    }
  }
  .bg img{
    opacity: 0.3;
  }
}
.stud-inner-card{
  position: relative;
  .nav__circle{
    margin: 0 auto;
  }
  .slick-track{
    display: flex;

    .slick-slide{
      display: flex;
      height: auto;
      align-items: center; //optional
      justify-content: center; //optional
      outline: none;
      >div{
        width: 100%;
        height: 100%;
      }
      .sr-slide{
        height: 100%;
      }
    }
  }
}
.stud-inner__nav{
  position: absolute;
  bottom: 60px;
  width: 100%;
  margin-right: -10px;
  margin-left: -10px;
  .stud-inner-slider-nav{
    max-width: 670px;
    margin: 0 auto;
    .sr-slide{
      height: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  @media (max-width: $breakpoint--phablet){
    bottom: 30px;
    padding-right: 30px;
    padding-left: 30px;
  }
}