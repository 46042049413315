.simple-grid{
   display: flex;
  flex-wrap: wrap;
  &__item{
    width: 50%;
    @media(max-width: $breakpoint--phablet){
      width: 100%;
    }
  }
}

.square-card{
  position: relative;
  display: block;
  background: $purple-cl;
  img{
    vertical-align: top;
    transition:opacity 0.3s ease-in-out;
  }
  &__title{
    position: absolute;
    left: 0;
    bottom: 0;
    color: $white-cl;
    font-size: 30px;
    font-family: $font-family-serif;
    padding: 35px 40px;
    z-index: 3;
    @media(max-width: $breakpoint--phablet){
      font-size: 24px;
      padding: 25px 30px;
    }
  }
  &:hover{
    background: $primary-cl;
    img{
      opacity: 0.4;
    }
  }
}