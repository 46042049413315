.footer{
  background: #1a224c top right no-repeat;
  &-wrap{
    max-width: 1170px;
    padding: 120px 40px 90px;
    margin: 0 auto;
    @media(max-width: $breakpoint--phone) {
      padding: 90px 25px;
    }
  }
  &-row{
    display: flex;
    margin:0 -15px;
    @media (max-width: $breakpoint--tablet){
      flex-wrap: wrap;
    }
  }
  &-col{
    width: 25%;
    padding: 0 15px;
    ul{
      list-style: none;
      padding: 0;
      margin: 0;
    }
    li{
      color: rgba($white-cl,0.75);
      font-family: $font-family-serif;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 30px;
      margin-bottom: 25px;
      @media(max-width: $breakpoint--tablet){
        font-size: 16px;
        line-height: 25px;
        margin-bottom: 20px;
      }
      @media(max-width: $breakpoint--phone){
        font-size: 13px;
        line-height: 21px;
        margin-bottom: 20px;
      }
    }
    a{
      color: rgba($white-cl,0.75);
      &:hover{
        color: $primary-cl;
      }
    }

    @media (max-width: $breakpoint--tablet){
      width: 50%;
      &:nth-child(3){
        order:4
      }
      &:nth-child(4){
        order:3;
      }
    }
    @media(max-width: $breakpoint--phablet) {
      .social-links{
        text-align: center;
      }
      &:nth-child(3){
        order: 3;
        .footer-title{
          display: none;
        }
      }
      &:nth-child(4){
        width: 100%;
        order: 4;
        padding-top: 30px;
      }

    }
  }
  &-title{
    color: $white-cl;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 30px;
    min-height: 30px;
    margin-bottom: 25px;
    @media(max-width: $breakpoint--phone){
      font-size: 15px;
      line-height: 24px;
      margin-bottom: 15px;
    }
  }
  &-bottom{
    display: flex;
    align-items: center;
    &>div{
      width: 33.3333%;
      color: rgba($white-cl, 0.35);
      font-size: 20px;
      font-weight: 600;
      @media(max-width: $breakpoint--tablet) {
        font-size: 14px;
      }
      @media(max-width: $breakpoint--phablet) {
        width: 100%;
      }
    }
    .go-top{
      text-align: right;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span{
        margin-left: 30px;
      }
      &:hover{
        color: $white-cl;
      }
      @media(max-width: $breakpoint--phablet) {
        text-align: center;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        span{
          margin-left: 10px;
        }
      }
    }
    @media(max-width: $breakpoint--phablet) {
      flex-direction: column-reverse;
      margin-top: 20px
    }
  }
  &-sketch{
    width: 100%;
    vertical-align: top;
    @media(max-width: $breakpoint--tablet){
      margin-top: -60px;
    }
  }
  &-arms{
    text-align: center;
    img{
      width: 100px;
    }
    @media(max-width: $breakpoint--phablet) {
      margin-bottom: 30px;
    }
  }
  @media(max-width: $breakpoint--phablet){
    background-position: bottom center;
  }
}

.soc-round{
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border: 1px solid rgba($white-cl,0.3);
  border-radius: 50%;
  margin-right: 8px;
  margin-bottom: 10px;
  text-align: center;
  padding-top: 12px;
  img{
    width: auto !important;
  }
  &:last-child{
    margin-right: 0;
  }

  &:hover{
    border: 1px solid rgba($white-cl,0);
  }
  &.icon-tw{
    padding-top: 15px;
  }
  &.icon-fb:hover{
    background: #3B5998;
  }
  &.icon-tw:hover{
    background: #55ACEE;
  }
  &.icon-inst:hover{
    background:  #c21975;
  }
  &.icon-yt:hover{
    background: #F3223C;
  }
  .social-links--in-content &{
    border: 1px solid rgba($white-cl,0);
    &.icon-fb{
      background: #3B5998;
      &:hover{
        background: darken(#3B5998, 10%);
      }
    }
    &.icon-tw{
      background: #55ACEE;
      &:hover{
        background: darken(#55ACEE, 10%);
      }
    }
    &.icon-inst{
      background:  #c21975;
      &:hover{
        background: darken(#c21975, 10%);
      }
    }
    &.icon-yt{
      background: #F3223C;
      &:hover{
        background: darken(#F3223C, 10%);
      }
    }
  }
}
.subscribe-block{
  padding: 10px 0;

  @media(max-width: $breakpoint--tablet) {
    padding: 5px 10px 20px 0;
  }
  @media(max-width: $breakpoint--phablet) {
    padding: 5px 0 20px 0;
  }
}