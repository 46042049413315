.vertical-link{
    position: absolute;
    right: 45px;
    top: 100px;
    color: $primary-cl;
    z-index: 5;
    width: 15px;
    white-space: nowrap;
    i{
        font-size: 12px;
    }
    &:hover{
        color: $white-cl;
    }
    @media(max-width: $breakpoint--phone){
        display: none;
    }
}
.vertical-txt{
    display: block;
    font-size: 15px;
    min-width: 90px;
    text-transform: uppercase;
    transform: rotate(90deg);
    transform-origin: 4px 11px;
}