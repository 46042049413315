.stat{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    &__item{
        position: relative;
        padding-right: 40px;
        width: 33.3333%;
        &:last-child{
            padding-right: 0;
        }
        @media(min-width: $breakpoint--tablet + 1){
            width: auto;
            //padding-right: 90px;
            min-width: 33.3333%;
        }
        @media(max-width: $breakpoint--phablet){
            padding-right: 0;
            text-align: center;
            //width: auto;
            //min-width: 0;
        }
    }
    &__count{
        display: block;
        //font-size: 60px;
        //line-height: 60px;
        font-size: 5vw;
        line-height: 5vw;
        font-family: $font-family-serif;
        @media(max-width: $breakpoint--phablet){
            font-size: 30px;
            line-height: 35px;
        }
    }
    &__icon{
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50px;
        //border:1px solid rgba(#fff, 0.6);

        img{
            vertical-align: top;
            width: 100%;
        }
        @media(min-width: $breakpoint--tablet + 1){
            position: absolute;
            left: 0;
            top:0;
        }
        @media(max-width: $breakpoint--phablet){
            width: 60px;
            height: 60px;
            margin: 0 auto;
        }
    }
    &__text{
        padding-top: 20px;

        @media(min-width: $breakpoint--tablet + 1){
            padding-top: 0;
            padding-left: 100px;
            display: flex;
            align-items: center;
            height: 80px;
        }
        @media(max-width: $breakpoint--phablet){
            padding-top: 15px;
        }
        @media(max-width: $breakpoint--phone){
            .fs20{
                font-size: 11px;
            }
        }
    }
    @media(max-width: $breakpoint--phablet){
        justify-content: space-between;
    }
}