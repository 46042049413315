.elementAnimate{
  opacity: 0;
  transition: 1s ease-in-out;
}
.divider-dash{width: 0;transition: 1s ease-in-out 0.2s;}
.elementAnimate.elDelay-1{transition-delay: 0s}
.elementAnimate.elDelay-2{transition-delay: 0.2s}
.elementAnimate.elDelay-3{transition-delay: 0.4s}
.elementAnimate.elDelay-4{transition-delay: 0.6s}
.elementAnimate.elDelay-5{transition-delay: 0.8s}
.elementAnimate.elDelay-6{transition-delay: 1.0s}
.elementAnimate.elDelay-7{transition-delay: 1.2s}
.elementAnimate.elDelay-8{transition-delay: 1.4s}
.elementAnimate.elDelay-9{transition-delay: 1.6s}
.elementAnimate.elDelay-10{transition-delay: 1.8s}

.elementAnimate.fadeInRight{
  transform: translateX(-100px);
}
.elementAnimate.fadeInLeft{
  transform: translateX(100px);
}
.elementAnimate.fadeInUp{
  transform: translateY(100px);
}
.elementAnimate.fadeInDown{
  transform: translateY(-100px);
}

.animated.elementAnimate{opacity: 1;}
.animated.elementAnimate.fadeInRight{transform: translateX(0)}
.animated.elementAnimate.fadeInLeft{transform: translateX(0)}
.animated.elementAnimate.fadeInUp{transform: translateY(0)}
.animated.elementAnimate.fadeInDown{transform: translateY(0)}
.animated.divider-dash{width: 70px;}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .elementAnimate{opacity: 1 !important;transform: none!important;transition: none!important;}
}
@supports (-ms-ime-align:auto) {
  .elementAnimate{opacity: 1 !important;transform: none!important;transition: none!important;}
  }

.hero{
  &__block {
    padding: 0;
    position: relative;
    overflow: hidden;
    .bg img{
      opacity: 0.45;
      &.more-opacity{
        opacity: .45;
      }
    }
    @media(min-width: $breakpoint--tablet + 1){
      min-height: 100vh;
      &.hero__block-short{
        min-height: 300px;
      }
    }
    &--map{
      .hero__caption{
        position: relative;
        .inner-wrap{
          padding-bottom:0;
        }
      }
    }
  }
  &__emblem{
    position: relative;
    z-index: 2;
    padding-top: 250px;
    @media(min-width: $breakpoint--tablet + 1){
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding-left: 300px;
      padding-top: 0;
    }

    .flex-emblem{
      display: flex;
      align-items: center;
    }
    .inner-wrap{
      padding-top: 0;
      padding-bottom: 60px;
    }
  }
  &__emblem-img{
    min-width: 100px;
    img{
      vertical-align: top;
    }
  }
  &__emblem-desc{
    padding-left: 20px;
  }
  &__caption{
    position: relative;
    z-index: 3;
    padding-top: 100px;
    @media(min-width: $breakpoint--tablet + 1){
      padding-top: 0;
      padding-left: 300px;
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    @media (min-width: $breakpoint--phone + 1) and (max-width: $breakpoint--phablet){
      padding-right: 40px;
    }
    &--align-top{
      position: relative;
      bottom: auto;
      top:0;
      .inner-wrap{
        padding-top: 100px;
        @media(max-width: $breakpoint--tablet){
          padding-top: 0;
        }
      }
    }
    &--align-center{
      position: relative;
      min-height: 100vh;
      display: flex;
      align-items: center;
    }
    .dashed-title{
      font-size: 3.55vw;
      line-height: 3.75vw;
      &:after{
        content: '';
        display: block;
        width: 70px;
        height: 5px;
        background: $primary-cl;
        margin-top: 15px;
      }
      @media(max-width: $breakpoint--tablet){
        font-size: 4vw;
        line-height: 4.3vw;
      }
      @media(max-width: $breakpoint--phablet){
        font-size: 32px;
        line-height: 40px;
      }
    }
    &-text{
      margin: 0;
      font-size: 15px;
      line-height: 2;
     // font-family: $font-family-appex;
      +p{
        margin-top: 30px;
      }
    }
  }
  &__profile-photo{
    max-width: 270px;
    background-color: $purple-cl;
    border-radius: 100%;
    @media (min-width: $breakpoint--phablet + 1){
      margin-right: 0;
      margin-left: auto;
    }
    img{max-width: 100%;vertical-align: top;border-radius: 50%;opacity: .75}
  }
  &__sidebar{
    position: fixed;
    bottom:0;
    left: 0;
    width: 300px;
    height: 100vh;
    background: rgba($white-cl, 0.9);
    padding: 0;
    z-index: 4;
    &.bottom{
      position: absolute;
      //height: auto;
    }
    @media(max-width: $breakpoint--tablet){
      display: none;
    }
  }
  &__sidebar-divider{
    height: 1px;
    width: 100%;
    border: none;
    border-top: 1px solid rgba($purple-cl,0.1);
    margin: 15px 0;
  }
  &__sidebar-after{
    padding: 10px 0;
    a{
      display: block;
      color: rgba($purple-cl,0.5);
      &:hover{
        color: $primary-cl;
      }
    }
  }
  &__sidebar-app{
    display: flex;
    align-items: center;
    padding: 10px 0;
    .app-icon{
      margin-right: 10px;
    }
  }
  &__sidebar-scroll{
    padding: 50px 40px;
    height:100%;
    overflow-y: auto;
  }
  &__sidebar-item{
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    padding: 15px 0 15px 15px;
    position: relative;
    color: rgba($purple-cl, 0.7);
    transition: 0.3s ease;
    display: block;
    a{color: rgba($purple-cl, 0.7);  transition: 0.3s ease;}
    i{
      position: absolute;
      left: -4px;
      top:16px;
      padding: 6px;
      font-size: 8px;
      vertical-align: middle;
      cursor: pointer;
      display: inline-block;
      transition: transform 0.2s ease;
    }

    &.showed{
      color: rgba($purple-cl, 1);
      a{
        color: rgba($purple-cl, 1);
      }
      &>i{
        transform: rotate(90deg);
      }
    }
    &:hover{
      color: $primary-cl;
      a{
        color: $primary-cl;
      }
    }
    &.active{
      color: rgba($purple-cl, 1);
      a{
        color: rgba($purple-cl, 1);
      }
    }
  }
  &__sidebar-sub-item{
    .hero__sidebar-item{
      text-transform: none;
      padding-top: 0px;
      padding-bottom: 8px;
      &>i{
        top:1px;
      }
    }
  }
  &__sidebar-submenu{
    list-style: none;
    padding: 0 0 0 14px;
    margin: 0;
    font-size: 15px;
    li{
      padding: 8px 0;
      &.active{
        &>a{
          color: $primary-cl;
        }
        &>.hero__sidebar-item{
          a{
            color: $primary-cl;
          }
        }
      }
    }
    a{
      color: $purple-cl;
      &:hover{
        color: $primary-cl;
      }
    }
  }
  &-flex{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &-img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12%;
  }
  &__event-info{
    color: $white-cl;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    i{
      margin-right: 12px;
    }
    .icon-calendar-start,
    .icon-calendar-end{
      font-size: 18px;
    }
    .icon-eye{
      font-size: 16px;
    }
    .icon-pin{
      font-size: 18px;
    }
    .icon-author{
      font-size: 16px;
    }
  }
  &__event-info-item{
    margin-right: 30px;
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
}
.hero-invert-row{
  @media (max-width: $breakpoint--phablet){
    flex-direction: column-reverse;
  }
}



.search-form{
  display: flex;
  align-items: center;
  max-width: 500px;
  input{
    flex-grow: 1;
    font-family: $font-family;
    font-size: 15px;
    margin-right: 15px;
    @media(max-width: $breakpoint--phablet){
      margin-right: 0;
      margin-bottom: 20px;
      width: 100%;
    }
  }
  &.w100{
    max-width: 100%;
  }
  .btn{
    height: 50px;
    min-width: 120px;
    @media(max-width: $breakpoint--phablet){
      width: 100%;
    }
  }
  @media(max-width: $breakpoint--phablet){
    flex-direction: column;
  }

  .input-type{
    max-width: 250px;
    width: 100%;
    @media screen and (max-width: $breakpoint--phablet){
      max-width: none;
      width: 100%;
    }
    +.select2{
      padding-left: 20px;
      padding-right: 40px;
      width: 100%!important;
      max-width: 250px;
      margin-right: 15px;

      @media screen and (max-width: $breakpoint--phablet){
        max-width: none;
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
      }

      .select2-selection--single .select2-selection__arrow{
        width: 40px;
        height: 50px;
      }
      .select2-selection--single{
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .select2-results__options{
        border-bottom-right-radius: 35px;
        border-bottom-left-radius: 35px;
      }
      .select2-results{
        width: 40px;
      }
      .select2-selection__rendered{
        text-transform: none !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
      }
      &-container{
        background: $white-cl;
        border-radius: 35px;
        line-height: 50px;
        height: 50px;
      }
    }
  }
}
.search-params{
  label{
    margin: 8px 30px 8px 0;
  }
}
.no-sidebar{
  .hero__sidebar{
    position: absolute;
  }
  .logo-inner-mob{
    display: block;
  }
  .hero__caption{
    padding-left: 0;
    padding-right: 0;
    .inner-wrap{
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}
.map-canvas{
  position: absolute;
  width: 100%;
  height: calc(100% + 30px);
  left:0;
  top:0;
}
.map-marker{
  background:url(../img/map_pin.svg) center center no-repeat;
  background-size:27px 35px;
  color:#fff;
  font-size:14px;
  line-height:30px;
  text-align:center;
  font-weight:700;
  &.selected{background:url(../img/map_pin_active.svg) center center no-repeat;background-size:27px 35px}
}
