.person{
  &__img{
    width: 170px;
    height: 170px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    background-color: rgba($purple-cl,.15);
    a{
      display: block;
      background-color: $purple-cl;
    }
    img{
      border-radius: 50%;
      vertical-align: top;
      text-indent: -10000px;
      opacity: .75;
    }
  }
  &__post{
    font-size: 15px;
    line-height: 20px;
    font-family: $font-family;
    color: rgba($purple-cl,0.5);
    font-weight: 600;
    margin-top: 20px;
  }
  &__name{
    font-size: 30px;
    line-height: 1;
    color: $dark-blue-cl;
    font-family: $font-family-serif;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 5px;
  }
  &__details{
    color: $primary-cl;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    position: relative;
    transition-delay: 250ms;
    overflow: hidden;
    transition: all 400ms ease;
    i{
      margin-left: 10px;
      font-size: 10px;
      transition: all 400ms ease;
    }
    .hover-only{
      margin-left: 0;
      position: absolute;
      top: 50%;
      left: 0;
      transition-delay: 0ms;
      transform: translate(-100%, -50%);
    }
    &:hover{
      padding-left: 30px;
      transition-delay: 0ms;
      .hover-only{
        transition-delay: 200ms;
        transform: translate(0, -50%);
      }
      i:not(.hover-only){
        transform: translateX(100%);
      }
    }
  }
}