.filter{
  &__row{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 60px;
    &.mb40{
      margin-bottom: 40px;
    }
    &--space-between{
      justify-content: space-between;
      @media(max-width: $breakpoint--phone){
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }
  }
  &__sort{
   max-width: 230px;
    width: 100%;
    &.w100{
      max-width: 100%;
    }
    >span:not(.select2){
      margin-right: 8px;
      font-size: 15px;
      font-weight: 600;
      color: $grey;
      line-height: 20px;
      text-transform: uppercase;
      vertical-align: middle;
    }
  }
  &__form{
    max-width: calc(100% - 230px);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media screen and (max-width: 1144px){
      max-width: none;
      justify-content: flex-start;
      margin-top: 20px;
    }
    @media screen and (max-width: 490px){
      flex-wrap: wrap;
    }
    &>*+*{
      margin-left: 15px;
    }

    .btn{
      font-size: 15px;
      margin-left: 15px;
      line-height: 15px;
      padding:8px 18px;
      font-weight: 600;
      text-transform: uppercase!important;
      @media screen and (max-width: 490px){
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    input[type="text"]{
      font-family: $font-family!important;
      height: 40px!important;
      max-width: 170px;
      font-size: 15px!important;
      padding-left: 20px!important;
      padding-right: 20px!important;
      @media screen and (max-width: 490px){
        max-width: none;
        width: 100%;
      }
    }


    .input-type{
      max-width: 170px;
      width: 100%;
      @media screen and (max-width: 490px){
        max-width: none;
        width: 100%;
      }
      +.select2{
        padding-left: 20px;
        padding-right: 40px;
        width: 100%!important;
        max-width: 170px;

        @media screen and (max-width: 490px){
          max-width: none;
          width: 100%;
          margin-left: 0;
          margin-top: 20px;
        }

        .select2-selection--single .select2-selection__arrow{
          width: 40px;
          height: 40px;
        }
        .select2-selection--single{
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .select2-results__options{
          border-bottom-right-radius: 35px;
          border-bottom-left-radius: 35px;
        }
        .select2-results{
          width: 40px;
        }
        .select2-selection__rendered{
          text-transform: capitalize!important;

        }
        &-container{
          background: $white-cl;
          border-radius: 35px;
          line-height: 40px;
          height: 40px;
        }
      }
    }
  }
}
.interest-filter-row{
  .filter__sort>span{
    margin-bottom: 5px;
  }
  .col-md-6{
    padding-bottom: 30px;
  }
}
.select2-dropdown {
  border-radius: 6px !important;
  margin-top: 5px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
  top: 100%;
  min-width: 150px;
}
.select2-results__option{
  padding: 10px 20px;
}
.select2-container--open .select2-dropdown--above{
  margin-top: -10px;
}
.add-to-fav{
  cursor: pointer;
  display: flex;
  align-items: center;
  @media(max-width: $breakpoint--phone){
   margin-top: 20px;
  }
  i{
    margin-right: 10px;
    font-size: 16px;
  }
}

.filter-search{
  width: 46%;
   input[type="text"]{
     width: 100%;
     font-size: 15px;
     font-family: $font-family;
   }
  @media(max-width: $breakpoint--phone){
    width: 100%;
    margin-top: 20px;
  }
}

