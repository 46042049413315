.blured-bar{
  background: #ccc;
  padding: 30px 0;
  transition: 0.6s ease-in-out;
  @media (max-width: $breakpoint--phablet) {
    font-size: 15px;
    &>div{
      order: 1;
    }
    .flex-nav__item{
      order: 2;
    }
  }
  &.movedUp{
    margin-top: -50px;
  }
}
.flex-nav{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
  &__item{
    //padding: 0 20px;
    padding: 0 2.5vw;
    @media (max-width: $breakpoint--phablet) {
      padding: 8px 0;
    }
  }
  @media (max-width: $breakpoint--phablet) {
     flex-direction: column;
  }
}
@media (max-width: $breakpoint--phone) {
  .section__caption .flex-nav{
    align-items: flex-start;
  }
}

