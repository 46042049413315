.toggle-search{
    position: fixed;
    left: 40px;
    bottom: 30px;
    z-index: 5;
    width: 50px;
    transition: 0.5s ease;

    svg {
        transform: scale(1.25);
    }

    form{
        display: block;
        margin: 0;
    }
    @media(max-width: $breakpoint--normal){
        left: 25px;
        bottom: 25px;
    }
    @media(max-width: $breakpoint--phone){
        left: 10px;
        bottom: 10px;
    }
    label{
        position: absolute;
        right:0;
        top:0;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        .svg-lupa{
            transition: 0.3s ease;
        }
        &:hover .svg-lupa{
            fill: $primary-cl;
        }
    }
    .icon-search{
        width: 50px;
        height: 50px;
        display: block;
        background: url(../img/lupa.svg) center center no-repeat;
        background-size: 16px 16px;
    }
    input.search-input{
        font-family: $font-family;
        border: none;
        width: 100%;
        font-size: 15px;
        padding: 2px 25px;
        opacity: 0;
        transition: 0.5s ease;
    }
    &.opened{
        width: 370px;
        @media(max-width: $breakpoint--phone){
            width: calc(100% - 20px);
        }
        input.search-input{
            opacity: 1;
            padding: 2px 45px 2px 25px;
        }
        .svg-lupa{
            fill: #888db0;
        }
    }
}

.sidebar-sticky {
    .svg-lupa {
       fill: $primary-cl; 
    }

    .toggle-search {
        &.opened {
            width: 220px;
        }
    }
}