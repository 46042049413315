.share-loc{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $breakpoint--phablet){
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
}
.share-block{
    display: flex;
    align-items: center;
    &__item{
        display: flex;
        align-items: center;
        margin-left: 30px;
        @media (max-width: $breakpoint--phablet){
            margin-left: 25px;
        }
        &:first-child{
            margin-left: 0;
        }
    }
}
.share-circle{
    width:24px;
    height: 24px;
    border-radius: 50%;
    background: #ccc;
    color: $white-cl;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-right: 15px;
    @media (max-width: $breakpoint--phablet){
        margin-right: 10px;
    }
    &.icon-instagram{font-size: 14px;
        background: #fbaf59;
        background: -moz-linear-gradient(45deg, #fbaf59 0%, #e13679 53%, #a03fc6 100%);
        background: -webkit-linear-gradient(45deg, #fbaf59 0%,#e13679 53%,#a03fc6 100%);
        background: linear-gradient(45deg, #fbaf59 0%,#e13679 53%,#a03fc6 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbaf59', endColorstr='#a03fc6',GradientType=1 );
    }
    &.icon-twitter{font-size: 10px;background: #55ACEE;}
    &.icon-facebook{font-size: 12px;background: #3B5998;}
}
.location{
    display: flex;
    align-items: center;
    padding-right: 40px;
    line-height: 24px;
    @media (max-width: $breakpoint--phablet){
        margin-bottom: 20px;
    }
    i{
        font-size: 16px;
        margin-right: 12px;
        &.icon-pin{
            font-size: 18px;
        }
    }
}