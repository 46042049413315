.scroll-down{
  cursor: pointer;
  position: fixed;
  right:55px;
  bottom:45px;
  z-index: 3;
  display: block;
  .mouse-icon{
    display: none;
    animation-duration: 1s;
    animation-name: updown;
    animation-iteration-count: infinite;
  }
  .go-top{
    color: #fff;
    white-space: nowrap;
    display: flex;
    align-items: center;
    span+span{
      margin-left: 10px;
    }
    &:hover{
      opacity: 0.8;
    }
  }
  &.showed{
    .mouse-icon{
      display: block;
    }
    .go-top{
      display: none;
    }
  }

  @media(max-width: $breakpoint--normal){
    right: 40px;
    bottom: 40px;
  }
  @media(max-width: $breakpoint--phone){
    right: 25px;
    bottom: 25px;
  }
}

.go-top-inner{
  cursor: pointer;
  position: fixed;
  right:55px;
  bottom:45px;
  z-index: 4;
  color: #a1a1b2;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgb(227, 231, 241);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  &.active{
    opacity: 1;
    visibility: visible;
  }
  img{
    display: block;
  }
  &:hover{
    background: #d8ddea;
  }
  @media(max-width: $breakpoint--normal){
    right: 15px;
    bottom: 15px;
  }
}

@keyframes updown {
    0% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(10px)
    }

    100% {
        transform: translateY(0)
    }
}
@-webkit-keyframes updown {0% {-webkit-transform: translateY(0)}50% {-webkit-transform: translateY(10px)}100% {-webkit-transform: translateY(0)}}

