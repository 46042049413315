.split{
  &__section{
    display: flex;
    @media(max-width: $breakpoint--phablet){
      flex-direction: column;
    }
  }
  &__item{
    overflow: hidden;
    width: 50%;
    padding:80px 50px;
    @media(max-width: $breakpoint--phablet){
      width: 100%;
    }
    @media (max-width: $breakpoint--phone){
      padding:50px 25px;
    }
  }
  &__caption{
    position: relative;
    z-index: 3;
    color: $white-cl;
    h3{
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 800;
      margin: 0 0 40px;
      line-height: 35px;
      @media (max-width: $breakpoint--phone){
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 30px;
      }
      &:after{
        content: '';
        display: block;
        width: 70px;
        height: 5px;
        background: $primary-cl;
        margin-top: 15px;
      }
    }
    p{
      font-size: 15px;
      line-height: 30px;
      margin: 0 0 40px;
      font-weight: 600;
    }
  }
}
.split-blured{
  .flex-nav__item{
    font-size: 20px;
    padding-left: 2vw;
    padding-right: 2vw;
    @media (max-width: $breakpoint--normal){
      font-size: 15px;
    }
  }
}