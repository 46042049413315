.btn{
  outline:none;
  background: $primary-cl;
  border:none;
  border-radius: 50px;
  padding: 25px 35px;
  cursor: pointer;
  margin: 0;
  line-height: 22px;
  overflow: hidden;
  font-size: $font-18;
  font-family: $font-family;
  font-weight: 600;
  display: inline-block;
  vertical-align: baseline;
  transition: $anim-02;
  -webkit-transition: $anim-02;
  -webkit-appearance: none;
  color: $white-cl;
  text-align: center;
  &:hover, &:active{
    background: darken($primary-cl,7%);
    border-color: darken($primary-cl,7%);
  }
  .icon{
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    @media(max-width: $breakpoint--phone){
      width: 24px;
    }
  }
  span{
    display: inline-block;
    vertical-align: middle;
  }

  @media (max-width: $breakpoint--tablet){
    font-size: $font-14;
    padding: 20px 30px;
  }
  @media (max-width: $breakpoint--phone){
    padding: 18px 30px;
    line-height: 21px;
  }
  &.bg-red{
    background: $primary-cl;
    color: #ffffff;
    &:hover, &:active{
      background: darken($primary-cl,7%);
      border-color: darken($primary-cl,7%);
    }
  }
  &.inverted{
    background: transparent;
    border:1px solid $primary-cl;
    color: $primary-cl;
    &:hover{
      background: $primary-cl;
      color: $white-cl;
    }
  }
}
.btn-gif{
  min-width: 240px;
  font-size: 30px;
  background: url(../img/gif-bg.jpg) center center no-repeat;
  background-size: cover;
  &:hover{
    background: url(../img/gif-bg.jpg) center center no-repeat;
    background-size: cover;
    transform: scale(1.05);
  }
  @media (max-width: $breakpoint--tablet){
    font-size: 24px;
    min-width: 205px;
  }
  @media(max-width: $breakpoint--phone){
    font-size: 20px;
    min-width: 170px;
  }
}

.btn-sm{
  &-bordered{
    background-color: transparent;
    border: 1px solid $primary-cl;
    padding: 8px 30px;
    color: $primary-cl;
    font-size:15px;
    text-transform: uppercase;
    &:hover{
      color: white;
    }
  }
  padding: 13px 30px;
  font-size:15px;
  border:1px solid $primary-cl;
}

.btn__wrap{
  display: flex;
  justify-content: center;
}
.btn-play{
  i{font-size: 12px;margin-right: 10px;vertical-align:baseline;}
}
.btn-set{
  .btn{
    margin-right: 15px;
    margin-bottom: 15px;
    &:last-child{
      margin-right: 0;
    }
    @media(max-width: $breakpoint--phone){
      margin-right: 0;
      width: 100%;
    }
  }
}
.mob-btn-wrap{
  @media(max-width: $breakpoint--phone){
    .btn{
      width: 100%;
      margin: 0;
      +.btn{
        margin-top: 20px;
      }
    }
  }
}
