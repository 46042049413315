.book{
  &__row{
    display: flex;
    flex-wrap: wrap;
    +.book__row{
      margin-top: 60px;
    }
    @media screen and (max-width: 640px){
      flex-direction: column;
    }

    &-left{
      max-width: 170px;
      width: 100%;
      img{
        display: block;
      }
    }
    &-right{
      max-width: calc(100% - 170px);
      width: 100%;
      padding-left: 30px;
      @media screen and (max-width: 640px){
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;
      }
      a.h4{
        font-weight: 500;
        font-size: $font-30;
        line-height: 1;
        margin-bottom: 15px;
        display: inline-block;
        font-family: $font-family-serif;
        color: $dark-blue-cl;
        &:hover{
          color: $primary-cl;
        }
      }
      a[download]{
        margin-top: 10px;
        display: inline-block;
        font-size: 15px;
        line-height: 20px;
        color: #7E8194;
        text-transform: uppercase;
        &:hover{
          color: $primary-cl;
        }
        i{
          margin-right: 15px;
          font-size: 18px;
        }
      }
    }

    +.btn__wrap{
      margin-top: 40px;
    }
  }

  &__actions{
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
    @media (max-width: $breakpoint--phablet) {
       flex-direction: column;
    }
    a{
      font-size: $font-30;
      color: white;
      font-family: $font-family-serif;
      font-weight: 600;
      &:hover{
        color: $primary-cl;
      }
      @media (max-width: $breakpoint--phablet) {
       font-size: 24px;
       margin-bottom: 10px;
      }
    }
  }
}
.pub-nav {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 0;
  margin-bottom: 25px;
  list-style: none;
  &__item {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba($white-cl, 0.7);
    margin-bottom: 8px;
    margin-right: 20px;
    a{
      color: rgba($white-cl, 0.6);
      &:hover{
        color: rgba($white-cl, 1);
      }
    }
    &.active a{
      color: $primary-cl;
    }
  }

}
