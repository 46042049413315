*,
*:before,
*:after {box-sizing: inherit;}
html {box-sizing: border-box;}
.round-loader {
  width: 40px;
  height: 40px;
  display: inline-block;
  border: 2px solid #262e63;
  border-radius: 50%;
  -webkit-animation: spin .5s infinite linear;
  -moz-animation: spin .5s infinite linear;
  animation: spin .5s infinite linear;
  border-bottom: 3px solid #F3223C;
}

#page-preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #1b224e;
  z-index: 30000;
}

.preloader-inner {
  height: 100vh;
  animation: fadein 1s ease-in alternate;

  .section {
    height: 100vh;
  }

  .sm-title {
    padding: 5% 0;
  }

}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}

.contpre small {
  font-size: 25px;
}

.contpre {
  width: 250px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 48%;
  margin-left: -125px;
  margin-top: -25px;
  color: #fff;
  font-size: 40px;
  letter-spacing: -2px;
  text-align: center;
  line-height: 35px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* iPad Input Shadows */
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="tel"]{
  -webkit-appearance: none;
  -moz-appearance: none;
}
html {
  font-family: sans-serif;
-ms-text-size-adjust: 100%;
-webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:  $font-base;
  font-family:  $font-family;
  color:$grey-cl;
  min-width: 280px;
}
html,
body {
    position: relative;
  background: $purple-cl;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}
a {
  background: transparent;
  text-decoration: none;
  outline: none;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
&:active,
&:hover {
  outline: none;
  text-decoration: none;
}
}
.primary-cl{
  color: $primary-cl
}
.grey-cl{
  color: $grey-cl
}
.white-cl{
  color: $white-cl
}
h1,.h1,.fs120,h2,.h2{
  margin: 0;
  font-size: $font-72;
  line-height: $line-heaight;
  font-weight: 300;
  b{
    font-weight: 700;
  }

  @media only screen and (max-width:$breakpoint--large){
    font-size: $font-60;
  }
  @media only screen and (max-width:$breakpoint--normal){
    font-size: $font-48;
  }
  @media only screen and (max-width:$breakpoint--phablet){
    font-size: $font-36;
  }
  @media only screen and (max-width:$breakpoint--phone){
    font-size:32px;
    line-height: 42px;
  }
}
h3,.h3,.fs36{
  margin: 0;
  font-size: $font-48;
  line-height: 1.3;
  @media only screen and (max-width: $breakpoint--large) {
    font-size: $font-30;
  }
}
h4,.h4{
   margin: 0;
   font-size: $font-24;
   font-weight: 300;
   b{
     font-weight: 700;
   }
  @media only screen and (max-width: $breakpoint--large) {
    font-size: $font-24;
    line-height: 28px;
  }
 }
h5,.h5,.fs24{
  margin: 0;
  font-size: $font-24;
  font-weight: 700;
  b{
    font-weight: 700;
  }
  @media only screen and (max-width: $breakpoint--large) {
    font-size: $font-18;
    line-height: 24px;
  }
}
h6,.h6{
  margin: 0;
  font-size: $font-16;
  font-weight: 500;
  b{
    font-weight: 700;
  }
}
.fs150{
  font-size: $font-150;
}
.fs100 {
    font-size: $font-100;
}
.fs60{
  font-size: $font-60;
}
.fs80 {
    font-size: $font-80;
}
.fs20 {
    font-size: $font-20;
  @media(max-width: $breakpoint--phablet){
    font-size: 16px;
  }
}
.bold{font-weight:700;}
.thin{font-weight: 300;}
.clearfix{clear: both;}
.clearfix:after{content: '';display: block;clear: both;}
.db{display: block;}
.dinb {display: inline-block;}
.fl{float: left;}
.fr{float: right;}
.tc{text-align: center;}
.tr{text-align: right;}
.tl{text-align: left;}
.rel{position: relative;}
.abs{position: absolute;}
.w100{width:100%;}
.mw100{max-width: 100%;}
.no-style{
    list-style:none;
    padding:0;
    margin:0;
    a:hover{
        color:$primary-cl;
    }
}
.light-cl{
  color: rgba($purple-cl,0.5);
}
.no-wrap{
  white-space: nowrap;
}
.uppercase{text-transform: uppercase;}
.h1-title{
  //font-size: 70px;
  //line-height: 70px;
  font-size: 5.85vw;
  line-height: 6vw;
  font-weight: 800;
  margin: 0;
  //@media (min-width: $breakpoint--large + 1) {
  //  font-size: 70px;
  //  line-height: 70px;
  //}
  @media (max-width: $breakpoint--phone) {
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    br{
      display: none;
    }
  }
}
.h2-title{
  //font-size: 50px;
  //line-height: 50px;
  font-size: 4vw;
  line-height: 4vw;
  font-weight: 800;
  margin: 0;
  //@media (min-width: $breakpoint--large + 1) {
  //  font-size: 50px;
  //  line-height: 50px;
  //}
  @media (max-width: $breakpoint--phone) {
    font-size: 32px;
    line-height: 40px;
  }
}
.sm-title{
  font-size: 2.55vw;
  line-height: 4.1vw;
  font-weight: 600;
  margin: 0;
  @media (min-width: $breakpoint--large + 1) {
    font-size: 30px;
    line-height: 50px;
  }
  @media(max-width: $breakpoint--phablet){
    font-size: 15px;
    line-height: 25px;
  }
  @media(max-width: $breakpoint--phone){
    br{
      display: none;
    }
  }
}
.sub-title{
  font-size: 2vw;
  line-height: 3.2vw;
  font-weight: 600;
  margin: 0;
  @media (max-width: $breakpoint--phablet) {
    font-size: 25px;
    line-height: 30px;
  }
  @media (min-width: $breakpoint--large + 1) {
    font-size: 30px;
    line-height: 30px;
  }
  @media (max-width: $breakpoint--phone) {
    font-size: 20px;
    line-height: 24px;
  }
}
.wrapper{
  max-width: 970px;
  margin: 0 auto;
  @media (min-width: $breakpoint--large + 1) {
     max-width: 75vw;
  }
}
.flex{
  display: flex;
}
.link-w{
  color: $white-cl;
  text-decoration: none;
  transition: 0.2s ease;
  &:hover{
    color: $primary-cl;
  }
}
.link-b{
  color: $purple-cl;
  text-decoration: none;
  transition: 0.2s ease;
  &:hover{
    color: $grey;
  }
}
.link-bp{
  color: $purple-cl;
  text-decoration: none;
  transition: 0.2s ease;
  &:hover{
    color: $primary-cl;
  }
}
.link-more{
  color: $primary-cl;
  &:hover{
    text-decoration: underline;
  }
}
.email{
  font-size: $font-30;
  color: $primary-cl;
  font-family: $font-family-serif;
  font-weight: 600;
}
.info-block{
  color: #ffffff;
  font-size: 15px;
  .p-text{
    line-height: 30px;
  }
}
.fw800{
  font-weight: 800;
}
.p-text{
  font-family: $font-family-serif;
  //font-size: 30px;
  //line-height: 45px;
  font-size: 2.5vw;
  line-height: 2.7vw;
  margin: 0;
  @media (min-width: $breakpoint--large + 1){
    font-size: 30px;
    line-height: 35px;
  }
  @media (max-width: $breakpoint--phablet) {
    font-size: 25px;
    line-height: 30px;
  }
  @media (max-width: $breakpoint--phone) {
    font-size: 20px;
    line-height: 30px;
  }
}
.divider-dash{
  display: block;
  margin: 0 auto 0 0;
  width: 70px;
  height: 5px;
  border: none;
  background: $primary-cl;
}
body .mr15{
  margin-right: 15px;
}
.mb15{
  margin-bottom: 15px;
}
.mb20{
  margin-bottom: 20px;
}
.mb25{
  margin-bottom: 25px;
}
.mb30{
  margin-bottom: 30px;
}
.mb35{
  margin-bottom: 35px;
  @media (min-width: $breakpoint--large + 1) {
    margin-bottom: 50px;
  }
  @media (max-width: $breakpoint--phone) {
    margin-bottom: 25px;
  }
}
.mb40{
  margin-bottom: 40px;
}
.mb60{
  margin-bottom: 60px;
}
.mb100{
  margin-bottom: 100px;
}
.mb80{
  margin-bottom: 80px;
  @media(max-width: $breakpoint--phablet){
    margin-bottom: 60px;
  }
}
.mt5{margin-top: 5px;}
.mt60{
  margin-top: 60px;
  @media(max-width: $breakpoint--phablet){
    margin-top: 30px;
  }
}
.mt50{
  margin-top: 50px;
}
.mt40{
  margin-top: 40px;
}
.mt-neg-50{
  margin-top: -50px;
  @media(max-width: $breakpoint--phablet) {
     margin-top: -30px;
  }
}
.mt-neg-20{
  margin-top: -20px;
  @media(max-width: $breakpoint--phablet) {
    margin-top: -10px;
  }
}
.max-450{
  max-width: 450px;
}
.pb22{
  padding-bottom: 22px !important;
}
.iScrollIndicator{
  background: none !important;
}

.c-scroll ::-webkit-scrollbar{
  width:4px;
  height: 10px;
  background: transparent;
}
.c-scroll ::-webkit-scrollbar-thumb{
  border-width:1px 1px 1px 2px;
  border-color: #f8f8fa;
  background-color: #f8f8fa;
  border-radius: 2px;
}
.c-scroll ::-webkit-scrollbar-thumb:hover{
  border-width: 1px 1px 1px 2px;
  border-color: #d8dfe3;
  background-color: #d8dfe3;
  height:10px;
}
.c-scroll ::-webkit-scrollbar-track{
  border-width:0;
}
.c-scroll ::-webkit-scrollbar-track:hover{
  border-left: none;
  background-color: transparent;
}
.mw500{
  max-width: 500px;
}

.blur-bg{
  filter: blur(10px);
}

@media screen and (max-width: 767px) {
  .mobile-hide{
    display: none;
  }
}
//.full-height{
//  min-height: 100vh;
//  @media screen and (max-width: 991px){
//    min-height: calc(100vh - 100px);
//  }
//}