.search-page{
  .hero__block{
    min-height: 450px;
  }
  .logo-inner{
    position: absolute;
    top: 46px;
    left: 55px;
    @media screen and (max-width: 991px){
      display: none;
    }
  }
  .inner-wrap{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 30px;
    @media (max-width: 1199px){
      padding: 80px;
    }
    @media (max-width: 767px){
      padding: 60px 40px;
    }
    @media (max-width: 520px){
      padding: 40px 25px;
    }

  }

  form{
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 767px){
      flex-wrap: wrap;
    }

    input{
      width: 100%;
      font-size: 15px;
      margin-right: 15px;
      @media screen and (max-width: 767px){
        margin-right: 0;
        margin-bottom: 20px;
      }
    }

    button{
      min-width: 120px;
      text-transform: uppercase;
      @media screen and (max-width: 767px){
        width: 100%;
      }
    }
  }

  .inner-container{
    padding-left: 0;
    &::before{
      display: none;
    }
  }

  .from-category{
    color: #F3223C;
    margin-left: 30px;
  }

  .article__da{
    margin-bottom: 15px;
  }

  .date{
    color: #7E8194;
    font-weight: 300;
  }

  .description{
    font-size: 15px;
    line-height: 30px;
    font-family: "Raleway", sans-serif;
    text-transform: initial;
  }

  .search-result-info{
    color: #7E8194;
    font-size: 15px;
    margin-bottom: 50px;
  }
}

.hero__caption-search-page{
  padding-left: 0;

}