.overlapped-img{
    margin-right: 10px;
    &__item{
        display: block;
        float: left;
        width: 40px;
        height: 40px;
        img{
            vertical-align: top;
            border-radius: 50%;
            max-width: 100%;
        }
        &+&{
            margin-left: -10px;
            border-radius: 50%;
            border:2px solid #403e63;
        }
    }
    &:after{
        content: '';
        display: block;
        clear: both;
    }
}